export const categories = [
    "kitchen",
    "tech",
    "prefium",
    "watches",
    "gaming",
    "self care",
    "solar power",
    "other",
];

/**
    * @typedef {("kitchen" | "tech" | "prefium" | "watches" | "gaming" | "self care" | "solar power" | "other")} Catrgory

/**
    *
    * @param {Catrgory} category
    * @returns {string}
    * */
export function categoriesInArabic(category) {
    switch (category) {
        case "tech":
            return "الكترونيات";
        case "gaming":
            return "العاب";
        case "kitchen":
            return "مطبخ";
        case "prefium":
            return "عطر";
        case "watches":
            return "ساعات";
        case "self care":
            return "عناية شخصية";
        case "solar power":
            return "طاقة شمسي";
        default:
            return "أخرى";
    }
}
