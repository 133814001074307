import { Link } from 'react-router-dom';
import TableRowsIcon from '@mui/icons-material/TableRows';
import AddIcon from '@mui/icons-material/Add';
import './productsmain.css';
import { useGetLocalUserData } from '../../../../functions/useGetLocalUserData';
import useAuthChecker from '../../useAuthChecker';

const ProductsMain = () => {
    const { userData } = useGetLocalUserData();
    useAuthChecker(userData);

  return (
    <div className="admin-products-page">
      <div className="container">
        <h1>Products</h1>
        <div className="boxes-container">
          <div className="list-products">
            <Link to="/admin/products/create-product">
              <AddIcon className="icon"/>
              <p>Create</p>
            </Link>
          </div>
          <div className="create-products">
            <Link to="/admin/products/list-products">
              <TableRowsIcon className="icon" />
              <p>List</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductsMain
