import SearchIcon from "@mui/icons-material/Search";
import { useState, useMemo, useEffect } from "react";
import LoadingPatternOne from "../loadingpatterns/LoadingPatternOne";
import "./catalog.css";
import { useGetLocalUserData } from "../../../functions/useGetLocalUserData";
import { api } from "../../../app/store";
import ProductItem from "./ProductItem";

const Catalog = () => {
    const [searchQuery, setSearchQuery] = useState("");
    const [requestPage, setRequestPage] = useState(1);

    const { data: allProducts, isLoading } = api.useGetProductsQuery();

    const numberOfPages = useMemo(() => allProducts ? Math.ceil(allProducts.length / 12) : 0, [allProducts]);

    useEffect(() => {

        // setting the current page to 1 when we start typing in the search bar
        setRequestPage(1);
    }, [searchQuery]);


    const shownProducts = useMemo(() => {

        // Update the products that should be shown depending on the current page and the search query

        const startIndex = (requestPage - 1) * 12;
        const endIndex = (requestPage) * 12;

        return (
            Array.isArray(allProducts) &&
            allProducts
                .filter((product) => searchQuery ? product.name.toLowerCase().includes(searchQuery) : true)
                .slice(startIndex, endIndex)
        );
    }, [allProducts, searchQuery, requestPage]);

    const { userData } = useGetLocalUserData();

    return (
        <div className="products-catalog">
            {isLoading && <LoadingPatternOne />}
            {allProducts && userData && (
                <div className="row-dashoard row">
                    <h1>كاتالوج المنتجات</h1>
                    <div className="search-input-container">
                        <input
                            id="searchBar"
                            className="search-input form-control form-control-sm"
                            type="text"
                            value={searchQuery}
                            placeholder="Search"
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <SearchIcon className="search-icon" />
                    </div>
                    <nav className="pagination-nav">
                        <ul className="pagination">
                            <li className={`page-item ${requestPage === 1 && "disabled"}`}><a onClick={() => setRequestPage(currentPage => currentPage - 1)} className="page-link" href="#">Previous</a></li>
                            {numberOfPages > 9
                                ? <>
                                    {requestPage === 1
                                        ? <>
                                            <li className="page-item active">
                                                <a className="page-link" href="#">{requestPage}</a>
                                            </li>
                                            <li className="page-item">
                                                <a onClick={() => setRequestPage(requestPage + 1)} className="page-link" href="#">{requestPage + 1}</a>
                                            </li>
                                            <li className="page-item">
                                                <a onClick={() => setRequestPage(requestPage + 2)} className="page-link" href="#">{requestPage + 2}</a>
                                            </li>
                                        </>
                                        : (requestPage === numberOfPages) ? <>
                                            <li className="page-item">
                                                <a onClick={() => setRequestPage(requestPage - 2)} className="page-link" href="#">{requestPage - 2}</a>
                                            </li>
                                            <li className="page-item">
                                                <a onClick={() => setRequestPage(requestPage - 1)} className="page-link" href="#">{requestPage - 1}</a>
                                            </li>
                                        </>
                                            : (requestPage === numberOfPages - 1) ?
                                                <><li className="page-item">
                                                    <a onClick={() => setRequestPage(requestPage - 1)} className="page-link" href="#">{requestPage - 1}</a>
                                                </li>
                                                    <li className="page-item active">
                                                        <a className="page-link" href="#">{requestPage}</a>
                                                    </li>
                                                </>
                                                : <><li className="page-item">
                                                    <a onClick={() => setRequestPage(requestPage - 1)} className="page-link" href="#">{requestPage - 1}</a>
                                                </li>
                                                    <li className="page-item active">
                                                        <a className="page-link" href="#">{requestPage}</a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a onClick={() => setRequestPage(requestPage + 1)} className="page-link" href="#">{requestPage + 1}</a>
                                                    </li>
                                                </>}

                                    {(requestPage !== numberOfPages
                                        && requestPage !== numberOfPages - 1
                                        && requestPage !== numberOfPages - 2) && <li className="page-item">
                                            <a className="page-link" href="#">...</a>
                                        </li>}
                                    <li className={`page-item ${requestPage === numberOfPages && "active"}`}>
                                        <a onClick={() => setRequestPage(numberOfPages)} className="page-link" href="#">{numberOfPages}</a>
                                    </li>
                                </>
                                : new Array(numberOfPages).fill(0).map((_, index) =>
                                    <li key={index} className={`page-item ${requestPage === index + 1 && "active"}`}>
                                        <a onClick={() => setRequestPage(index + 1)} className="page-link" href="#">{index + 1}</a>
                                    </li>
                                )}
                            <li className={`page-item ${requestPage === numberOfPages && "disabled"}`}><a onClick={() => setRequestPage(currentPage => currentPage + 1)} className="page-link" href="#">Next</a></li>
                        </ul>
                    </nav>
                    <div className="products-list">
                        {shownProducts
                            .map((product) => {
                                return (
                                    <ProductItem
                                        productData={JSON.stringify(product)}
                                        key={product._id}
                                        favProducts={
                                            userData.favorite_products &&
                                            JSON.stringify(userData.favorite_products)
                                        }
                                    />
                                );
                            })}
                    </div>
                    <nav className="pagination-nav">
                        <ul className="pagination">
                            <li className={`page-item ${requestPage === 1 && "disabled"}`}><a onClick={() => setRequestPage(currentPage => currentPage - 1)} className="page-link" href="#">Previous</a></li>
                            {numberOfPages > 9
                                ? <>
                                    {requestPage === 1
                                        ? <>
                                            <li className="page-item active">
                                                <a className="page-link" href="#">{requestPage}</a>
                                            </li>
                                            <li className="page-item">
                                                <a onClick={() => setRequestPage(requestPage + 1)} className="page-link" href="#">{requestPage + 1}</a>
                                            </li>
                                            <li className="page-item">
                                                <a onClick={() => setRequestPage(requestPage + 2)} className="page-link" href="#">{requestPage + 2}</a>
                                            </li>
                                        </>
                                        : (requestPage === numberOfPages) ? <>
                                            <li className="page-item">
                                                <a onClick={() => setRequestPage(requestPage - 2)} className="page-link" href="#">{requestPage - 2}</a>
                                            </li>
                                            <li className="page-item">
                                                <a onClick={() => setRequestPage(requestPage - 1)} className="page-link" href="#">{requestPage - 1}</a>
                                            </li>
                                        </>
                                            : (requestPage === numberOfPages - 1) ?
                                                <><li className="page-item">
                                                    <a onClick={() => setRequestPage(requestPage - 1)} className="page-link" href="#">{requestPage - 1}</a>
                                                </li>
                                                    <li className="page-item active">
                                                        <a className="page-link" href="#">{requestPage}</a>
                                                    </li>
                                                </>
                                                : <><li className="page-item">
                                                    <a onClick={() => setRequestPage(requestPage - 1)} className="page-link" href="#">{requestPage - 1}</a>
                                                </li>
                                                    <li className="page-item active">
                                                        <a className="page-link" href="#">{requestPage}</a>
                                                    </li>
                                                    <li className="page-item">
                                                        <a onClick={() => setRequestPage(requestPage + 1)} className="page-link" href="#">{requestPage + 1}</a>
                                                    </li>
                                                </>}

                                    {(requestPage !== numberOfPages
                                        && requestPage !== numberOfPages - 1
                                        && requestPage !== numberOfPages - 2) && <li className="page-item">
                                            <a className="page-link" href="#">...</a>
                                        </li>}
                                    <li className={`page-item ${requestPage === numberOfPages && "active"}`}>
                                        <a onClick={() => setRequestPage(numberOfPages)} className="page-link" href="#">{numberOfPages}</a>
                                    </li>
                                </>
                                : new Array(numberOfPages).fill(0).map((_, index) =>
                                    <li key={index} className={`page-item ${requestPage === index + 1 && "active"}`}>
                                        <a onClick={() => setRequestPage(index + 1)} className="page-link" href="#">{index + 1}</a>
                                    </li>
                                )}
                            <li className={`page-item ${requestPage === numberOfPages && "disabled"}`}><a onClick={() => setRequestPage(currentPage => currentPage + 1)} className="page-link" href="#">Next</a></li>
                        </ul>
                    </nav>
                </div>
            )}
        </div>
    );
};

export default Catalog;
