import { useState } from "react";
import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import DashboardNavbar from "../dashboardNavbar/DashboardNavbar";
import Sidebar from "../sidebar/Sidebar";
import LoadingPatternOne from "../loadingpatterns/LoadingPatternOne";
import "./orders.css";
import changeOrderState from "../../../functions/changeOrderState";
import { api } from "../../../app/store";

const determineColor = (state) => {
  switch (state) {
    case "معلقة":
      return "table-warning";
    case "قيد التسليم":
      return "table-primary";
    case "مسلمة":
      return "table-success";
    case "ملغية":
      return "table-danger";
    default:
      return "";
  }
};

const Orders = () => {
  const [changeState] = api.useChangeOrderStateMutation({
    fixedCacheKey: "orders",
  });

  const [ordersFilter, setOrdersFilter] = useState("الكل");
  const { data: orders, isLoading } = api.useGetOrdersForUserQuery(
    sessionStorage.getItem("userId")
  );

  return (
    <div className="orders-page">
      {isLoading && <LoadingPatternOne />}
      {orders && (
        <div className="row-dashoard row">
          <h1>الطلبات</h1>
          <div className="order-state-filter">
            <select
              value={ordersFilter}
              onChange={(e) => setOrdersFilter(e.target.value)}
              id="orderStateFilter"
              className="form-select"
            >
              <option value="الكل">الكل</option>
              <option value="معلقة">معلقة</option>
              <option value="قيد التسليم">قيد التسليم</option>
              <option value="مسلمة">مسلمة</option>
              <option value="ملغية">ملغية</option>
            </select>
          </div>
          <div className="orders-table">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">تاريخ الطلب</th>
                  <th scope="col">الإجمالي</th>
                  <th scope="col">رابط الشحنة</th>
                  <th scope="col">عدد المنتجات</th>
                  <th scope="col">الحالة</th>
                  <th scope="col">الإجراءات</th>
                </tr>
              </thead>
              <tbody>
                {orders
                  .filter((order) =>
                    ordersFilter !== "الكل"
                      ? order.order_state === ordersFilter
                      : true
                  )
                  .map((order, index) => {
                    const orderDate = new Date(order.createdAt);
                    const orderColor = determineColor(order.order_state);
                    return (
                      <tr key={order._id} className={orderColor}>
                        <th scope="row">{index + 1}</th>
                        <td>{orderDate.toLocaleDateString()}</td>
                        <td>{order.taken_from_client} د.أ</td>
                        <td>لا يوجد</td>
                        <td>{order.products.length}</td>
                        <td>{order.order_state}</td>
                        <td>
                          {order.order_state !== "ملغية" &&
                            order.order_state !== "مسلمة" && (
                              <CloseIcon
                                onClick={() => {
                                  changeState({
                                    orderId: order._id,
                                    body: { order_state: "ملغية" },
                                  })
                                    .unwrap()
                                    .then((res) => {
                                      if (res !== "Changed order state")
                                        throw new Error("مشكلة");
                                    })
                                    .catch((err) => {
                                      console.log(err);
                                    });
                                }}
                                style={{
                                  color: "red",
                                  cursor: "pointer",
                                  marginLeft: 10,
                                }}
                              />
                            )}
                          <Link to={`/dashboard/orders/details/${order._id}`}>
                            <SearchIcon />
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default Orders;
