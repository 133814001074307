import Swal from "sweetalert2";
import { api } from "../app/store";

export function useRemoveProductFromCart(productId) {
  const [ removeFromCart ] = api.useRemoveProductFromCartMutation({
    fixedCacheKey: "shopping-cart-products"
  });

  return () => 
    removeFromCart({product_id: productId})
      .unwrap()
      .then(res => {
      if(res !== "deleted item form cart") throw Error("حدث خطأ ما, حاول لاحقا!");
      })
      .catch(err => {
        Swal.fire(
          'مشكلة!',
          `${err}`,
          'error'
        )
      });

}