import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer';
import './about.css';

const Aboutus = () => {
  return (
    <div className="about-us-page">
      <Navbar />
      <div className="container">
        <h1 className="about-heading">من نحن</h1>
        <div className="generally">
          <h2>عن بريميوم ستور</h2>
          <p>بريميوم ستور هي منصة الكترونية تساعدك لتبدأ مشروعك الإلكتروني بدون رأس مال ،ما عليك الا ان تنشئ صفحة او متجر الكتروني ونحن نزودك بكافة المنتجات من أفضل المودرين في الأردن بالإضافة الى شحن وتغليف مضمون للطلبات وتحصيل ارباحك من العميل</p>
        </div>
        <div className="our-goals">
          <h2>أهدافنا</h2>
          <p>مساعدة الشباب ورواد الأعمال في الأردن لتأسيس مشاريعهم في التجارة الإلكترونية</p>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Aboutus