import LoadingPatternOne from '../../../dashboard/loadingpatterns/LoadingPatternOne';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import './listproducts.css';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { api } from '../../../../app/store';
import { useGetLocalUserData } from '../../../../functions/useGetLocalUserData';
import useAuthChecker from '../../useAuthChecker';

const ListProducts = () => {

    const { userData } = useGetLocalUserData();
    useAuthChecker(userData);

    const { data: products, isLoading } = api.useGetProductsQuery();

    const [deleteProductApi] = api.useDeleteProductMutation({
        fixedCacheKey: "products"
    });

    const deleteProduct = (productId, target) => {
        Swal.fire({
            title: 'هل أنت متأكد',
            text: "هل ترغب حقا بحذف المنتج؟",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'نعم متأكد!'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteProductApi(productId)
                    .unwrap()
                    .then(result => {
                        if(result !== "Deleted Product") throw Error("مشكلة!");
                        Swal.fire(
                            'تمت العملية!',
                            'تم حذف المنتج بنجاح',
                            'success'
                        );
                    })
                    .catch(err => {
                        Swal.fire(
                            `${err}`,
                            'حدث خطأ أثناء الحذف',
                            'error'
                        );
                    });
            }
        })

    };

  if(isLoading) return <LoadingPatternOne />;

  return (products &&
    <div className="container products-list">
      <h1>Products list</h1>
      <div className="list">
        {products.map(product => (
          <div className="products-list-item" key={product._id}>
            <img src={product.pictures[0].pic_url} alt="" />
            <div className="product-details">
              <div className="product-data">
                <h4>{product.name}</h4>
                <p dir="rtl" align="left">{product.price} دينار </p>
              </div>
              <div className="product-buttons">
                <button className='btn btn-danger' onClick={e => deleteProduct(product._id, e.target)}><DeleteIcon /> Delete</button>
                <Link to={`/admin/products/edit/${product._id}`}>
                  <button className='btn btn-success'><EditIcon /> Edit</button>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ListProducts
