import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import './supplier.css';
import Swal from 'sweetalert2';

const SupplierEmail = ({ setShowAlert }) => {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_0yac22e', 'template_stm7y7k', form.current, '8-0Skc2jvq0NHARRL')
      .then((result) => {
        console.log(result.text);
        Swal.fire('تم الارسال!', 'تم ارسال رسالتك بنجاح!', 'success');
      }, (error) => {
        Swal.fire('Error!', 'حدث خطأ ما!', 'error');
      });
  };

  return (
    <div dir='rtl'>
      <form ref={form} onSubmit={sendEmail}>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">البريد الاكتروني</label>
          <input type="text" className="form-control" id="email" name="supplier_email" placeholder="name@example.com" />
        </div>
        <div className="mb-3">
          <label htmlFor="phoneNumber" className="form-label">رقم الهاتف</label>
          <input type="text" className="form-control" id="phoneNumber" name="phone_number" placeholder="07********" />
        </div>
        <div className="mb-3">
          <label htmlFor="productCategory" className="form-label">تصنيف المنتجات</label>
          <input type="text" className="form-control" id="productCategory" name="products_category" placeholder="أدوات منزل, الكترونيات,..." />
        </div>
        <div className="mb-3">
          <label htmlFor="companyName" className="form-label">اسم الشركة</label>
          <input type="text" className="form-control" name="company_name" id="companyName" />
        </div>
        <div className="mb-3">
          <label htmlFor="exampleFormControlTextarea1" className="form-label">الرسالة</label>
          <textarea className="form-control" id="exampleFormControlTextarea1" name="supplier_message" rows="3"></textarea>
        </div>
        <button type="submit" className="btn btn-danger" >إرسال</button>
      </form>
    </div>
  )
}

export default SupplierEmail
