/*
@TODO : Forget Password Option
*/

import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { connnection } from '../../shared/connection';
import './signin.css';

const Signin = () => {
  if(sessionStorage.getItem("userId")) window.location.pathname = '/';

  const [signinError, setSigninError] = useState(false);
  const [buttonValue, setButtonValue] = useState('دخول')

  const showPassword = () => {
    let input = document.querySelector("#passwordField");
    input.setAttribute("type", "text");
  };

  const hidePassword = () => {
    let input = document.querySelector("#passwordField");
    input.setAttribute("type", "password");
  };

  const handleSubmit = e => {
    e.preventDefault();
    setButtonValue('جار التسجيل...');
    setSigninError(false);
    fetch(connnection + "auth/signin", {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify({
        email: document.querySelector("#emailField").value,
        password: document.querySelector("#passwordField").value
      })
    })
    .then(res => {
      if(!res.ok) {
        throw Error(`Something went wrong`);
      }
      return res.json();
    })
    .then(data => {
      sessionStorage.setItem("userId", data.id);
      window.location.pathname = '/dashboard';
    })
    .catch(err => {
      setSigninError(true);
      setButtonValue('دخول');
    });
  }

  return (
    <div className="sign-in">
      <div className="container">
        <h1>تسجيل الدخول</h1>
        <form onSubmit={handleSubmit} className="sign-in-form">
          <div className="input-group mb-3">
            <input id="emailField" type="email" className="form-control" aria-label="Recipient's email" aria-describedby="basic-addon2" required/>
            <span className="input-group-text">البريد الاكتروني</span>
          </div>
          <div className="input-group mb-3">
            <input id="passwordField" type="password" className="form-control" placeholder="كلمة المرور" aria-label="Recipient's password" aria-describedby="basic-addon2" required/>
            <span className="input-group-text" onMouseOver={showPassword} onMouseOut={hidePassword}><RemoveRedEyeIcon /></span>
          </div>
          <p className="has-no-acc">لا تملك حساب؟ قم <Link to="/sign-up">بإنشاء حساب</Link></p>
          {signinError && <div className="alert alert-danger" role="alert">
            الرجاء التأكد من البريد الالكتروني وكلمة المرور
          </div>}
          <input className="btn" type="submit" id="loginSubmitButton" value={buttonValue} />
        </form>
      </div>
    </div>
  )
}

export default Signin
