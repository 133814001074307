import Swal from "sweetalert2";
import { api } from "../app/store";
import { useLocation } from "react-router-dom";

export function useAddProductToCart(productId, suggestedPrice) {

    const loc = useLocation();

    const [addToCart] = api.useAddProductToCartMutation({
        fixedCacheKey: "shopping-cart-products"
    });

    return () =>
        addToCart({
            product_id: productId,
            selling_price: suggestedPrice,
        })
            .unwrap()
            .then((res) => {
                if (res !== "Added to Shopping Cart")
                    throw Error("حدث خطأ أثناء إضافة المنتج");
                Swal.fire({
                    icon: "success",
                    title: "تم إضافة المنتج"
                }).then(() => {
                    if (loc.pathname.includes("dashboard/catalog/details")) window.location.pathname = "/dashboard/catalog";
                });
            })
            .catch(err => {
                Swal.fire({
                    icon: "error",
                    title: "مشكلة!",
                    html: `${err}`
                })
            })
}
