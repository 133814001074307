import { useState } from 'react';
import LoadingPatternOne from '../../dashboard/loadingpatterns/LoadingPatternOne'
import './withdraw_orders.css';
import { api } from '../../../app/store';
import { useGetLocalUserData } from '../../../functions/useGetLocalUserData';
import OrderItem from './order_item/OrderItem';
import useAuthChecker from '../useAuthChecker';

const WithdrawOrdersMain = () => {

    const { userData } = useGetLocalUserData();
    useAuthChecker(userData);

    const [filter, setFilter] = useState('الكل');
    const { data: withdrawOrders, isLoading } = api.useGetWithdrawOrdersQuery();

    const handleFilter = e => {
        setFilter(e.target.value);
    };

    if(isLoading) {
        return <LoadingPatternOne />
    }

  return (withdrawOrders &&
    <div className="admin-orders-page">
      <div className="container">
        <h1>Withdraw orders</h1>
        <select onChange={handleFilter} value={filter} className="form-select">
          <option value="الكل">الكل</option>
          <option value="pending">معلقة</option>
          <option value="approved">مسلمة</option>
          <option value="rejected">ملغية</option>
        </select>
        <table className="table" dir="rtl">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">اسم التاجر</th>
              <th scope="col">المبلغ</th>
              <th scope="col">التاريخ</th>
              <th scope="col">معرف كليك</th>
              <th scope="col">الاجراءات</th>
            </tr>
          </thead>
          <tbody>
            {withdrawOrders
            .filter((order) => filter ==='الكل' || order.state === filter)
            .map((order, index) => {
                console.log(order);
              return <OrderItem
                userId={order.userId}
                key={order._id}
                index={index+1}
                amount={order.amount}
                date={order.createdAt}
                state={order.state}
                id={order._id}
              />
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default WithdrawOrdersMain;
