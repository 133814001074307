import { useState } from "react";
import "./changeorderstate.css";
import { api } from "../../../../app/store";

const ChangeOrderState = ({ order }) => {
  order = JSON.parse(order);
  const [orderState, setOrderState] = useState(order.order_state);

  const [changeState] = api.useChangeOrderStateMutation({
    fixedCacheKey: "orders",
  });

  const [incrementDeliveredOrders] = api.useIncrementDeliveredOrdersMutation({
    fixedCacheKey: "orders",
  });

  const [incrementRevenue] = api.useIncrementRevenueByOrderMutation({
    fixedCacheKey: "orders",
  });

  const changeTheOrderState = (e) => {
    setOrderState(e.target.value);
    changeState({
      orderId: order._id,
      body: { order_state: e.target.value },
    })
      .unwrap()
      .then((res) => {
        if (res !== "Changed order state") throw new Error("مشكلة!");
        if (e.target.value === "مسلمة") {
          incrementDeliveredOrders({
            sellerId: order.sellerId,
            body: {
              orderId: order._id,
              createdAt: new Date(),
            },
          })
            .unwrap()
            .then((res) => {
              if (res !== "Success") throw new Error("مشكلة!");
              incrementRevenue({
                sellerId: order.sellerId,
                body: { addedRev: order.total_products_profit },
              })
                .unwrap()
                .then((res) => {
                  if (res !== "Revenue Added") throw new Error("مشكلة!");
                })
                .catch((err) => console.log(`Error: ${err}`));
            })
            .catch((err) => console.log(`Error: ${err}`));
        }
      })
      .catch((err) => console.log(`Error: ${err}`));
  };

  return (
    <div className="admin-change-order-state">
      {orderState === "مسلمة" || orderState === "ملغية" ? (
        <p>لا يمكن تغيير حالة الطلب</p>
      ) : (
        <>
          <p>تغيير حالة الطلب</p>
          <select value={orderState} onChange={(e) => changeTheOrderState(e)}>
            <option value="معلقة">معلقة</option>
            <option value="ملغية">ملغية</option>
            <option value="قيد التسليم">قيد التسليم</option>
            <option value="مسلمة">مسلمة</option>
          </select>
        </>
      )}
    </div>
  );
};

export default ChangeOrderState;
