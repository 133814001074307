import { Link } from 'react-router-dom';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import StarIcon from '@mui/icons-material/Star';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import PersonIcon from '@mui/icons-material/Person';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import HelpIcon from '@mui/icons-material/Help';
import SpeedIcon from '@mui/icons-material/Speed';
import './sidebar.css';

const Sidebar = () => {
    return (
        <div className="sidebar">
            <div className="logo">
                <Link to="/"><img src="../resources/premium_store.png" alt="" /></Link>
            </div>
            <div className="store">
                <h6>المتجر</h6>
                <Link to="/dashboard/catalog"><span>كاتالوج المنتجات </span><FormatListBulletedIcon /></Link>
                <Link to="/dashboard/favorite-products"><span>منتجاتي </span><StarIcon /></Link>
                <Link to="/dashboard/orders"><span>الطلبات </span><ReceiptLongIcon /></Link>
            </div>
            <div className="account">
                <h6>الحساب</h6>
                <Link to="/dashboard/profile"><span>إعدادات الحساب </span><PersonIcon /></Link>
                <Link to="/dashboard/wallet"><span>المحفظة المالية </span><AccountBalanceWalletIcon /></Link>
            </div>
            <div className="others">
                <h6>أخرى</h6>
                <Link><span>الجوائز </span><CardGiftcardIcon /></Link>
                <Link><span>مركز الدعم </span><HelpIcon /></Link>
            </div>
        </div>
    )
}

export const LayoutSidebar = () => {
    return (
        <div className="layout-sidebar">
            <div className="logo">
                <Link to="/"><img src="../resources/premium_store.png" alt="" /></Link>
            </div>
            <div className="store">
                <h6>المتجر</h6>
                <Link to="/dashboard/"><span>لوحة التحكم </span><SpeedIcon /></Link>
                <Link to="/dashboard/catalog"><span>كاتالوج المنتجات </span><FormatListBulletedIcon /></Link>
                <Link to="/dashboard/favorite-products"><span>منتجاتي المفضلة</span><StarIcon /></Link>
                <Link to="/dashboard/orders"><span>الطلبات </span><ReceiptLongIcon /></Link>
            </div>
            <div className="account">
                <h6>الحساب</h6>
                <Link to="/dashboard/profile"><span>إعدادات الحساب </span><PersonIcon /></Link>
                <Link to="/dashboard/wallet"><span>المحفظة المالية </span><AccountBalanceWalletIcon /></Link>
            </div>
            <div className="others">
                <h6>أخرى</h6>
                { /* <Link><span>الجوائز </span><CardGiftcardIcon /></Link> */}
                <a href="mailto: premiumstoreconnect@gmail.com"><span>مركز الدعم </span><HelpIcon /></a>
            </div>
        </div>
    )
}

export default Sidebar
