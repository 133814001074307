import './home.css';

const Aboutus = () => {
  return (
    <div className="about-us">
      <div className="container">
        <h1>من نحن</h1>
        <p>منصة بريميوم ستور هي منصة إلكترونية تساعدك لتبدأ مشروعك الإلكتروني بدون رأس مال ، كل ما عليك هو أن تنشئ صفحة أو متجر إلكتروني، و نحن سنزودك بكافة المنتجات من أفضل الموردين في الأردن و نضمن لك شحن وتغليف ممتاز للطلبات وتحصيل المبالغ من الزبون</p>
      </div>
    </div>
  )
}

export default Aboutus