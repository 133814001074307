import './supplierdata.css';

const SupplierData = ({ supplierName, creationDate, orderState }) => {
  return (
    <div className="supplier-data" dir="rtl">
      <p><span> اسم المسوق</span><span> {supplierName}</span></p>
      <p><span> تاريخ إنشاء الطلب</span><span> {new Date(creationDate).toDateString()}</span></p>
      <p><span> حالة الطلب</span><span> {orderState}</span></p>
    </div>
  )
}

export default SupplierData