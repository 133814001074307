import { Link } from 'react-router-dom';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import './home.css';

const Contact = () => {
    return (
        <div className="contact-us">
            <div className="container">
                <h1>تواصل معنا</h1>
                <div className="row-dashoard row">
                    <div className="col-md-6">
                        <div className="left-box">
                            <h2>عبر البريد</h2>
                            <div className="button-container"><Link to='/contact-us' className="btn">اضغط هنا</Link></div>
                        </div>
                    </div>
                    <div className="col-md-6 right-box">
                        <a href="https://www.instagram.com/premiumstore_at/" target="_blank" className="contact-method">
                            <span>عبر انستغرام</span>
                            <InstagramIcon className="contact-icon" />
                        </a>
                        <a href="https://www.facebook.com/profile.php?id=100076437956984&mibextid=ZbWKwL" target="_blank" className="contact-method">
                            <span>عبر فيسبوك</span>
                            <FacebookIcon className="contact-icon" />
                        </a>
                        <a href="https://www.linkedin.com/in/premium-store-488128297?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" target="_blank" className="contact-method">
                            <span>عبر لينكد ان</span>
                            <LinkedInIcon className="contact-icon" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact
