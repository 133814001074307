import useAuthChecker from '../useAuthChecker';
import { Link } from 'react-router-dom';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import { useGetLocalUserData } from '../../../functions/useGetLocalUserData';
import './adminhome.css';

const AdminHome = () => {

    const { userData } = useGetLocalUserData();
    useAuthChecker(userData);

    return (userData && userData.isAdmin &&
        <div className="admin-homepage">
            <div className="container">
                <h1>Admin Screen</h1>
                <div className="boxes-container">
                    <div className="users">
                        <Link to="/admin/users">
                            <PeopleAltIcon className='icon' />
                            <p>Users</p>
                        </Link>
                    </div>
                    <div className="orders">
                        <Link to="/admin/orders">
                            <ReceiptLongIcon className='icon' />
                            <p>Orders</p>
                        </Link>
                    </div>
                    <div className="withdraw-orders">
                        <Link to="/admin/withdraw-orders">
                            <CurrencyExchangeIcon className='icon' />
                            <p>Withdraw orders</p>
                        </Link>
                    </div>
                    <div className="products">
                        <Link to="/admin/products">
                            <Inventory2Icon className='icon' />
                            <p>Products</p>
                        </Link>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default AdminHome;
