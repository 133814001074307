import './ordersummary.css';

const OrderSummary = ({ order }) => {
  const orderData = JSON.parse(order);
  return (
    <div dir="rtl" className="order-summary">
      <h2>ملخص الطلب</h2>
      <p className="flex-container"><span>تكلفة الشحن</span><span>2.75 د.أ</span></p>
      <p className="flex-container"><span>تكلفة تجهيز الطلب</span><span>1 د.أ</span></p>
      <p className="flex-container"><span>مدة التوصيل</span><span>3-1 أيام عمل</span></p>
      <p className="flex-container"><span>المطلوب من الزبون</span><span>{orderData.taken_from_client}</span></p>
      <p className="flex-container"><span>إجمالي التكلفة على المسوق</span><span>{orderData.total_products_cost+1+2.75}</span></p>
      <p className="flex-container"><span>إجمالي الربح</span><span>{orderData.total_products_profit}</span></p>
    </div>
  )
}

export default OrderSummary