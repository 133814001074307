import { Link } from "react-router-dom";
import './navbar.css';

const Navbar = () => {

  const signOut = () => {
    sessionStorage.removeItem("userId");
    window.location.reload();
  }

  return (
    <div className="navbar-container">
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav">
                  {sessionStorage.getItem("userId") && <>
                    <li className="nav-item"><Link className="nav-link" to="/dashboard">لوحة التحكم</Link></li>
                    <li className="nav-item"><a className="nav-link" onClick={signOut} href="/">تسجيل خروج</a></li>
                  </>}
                  {(sessionStorage.getItem("userId") == null) && <>
                    <li className="nav-item"><a className="nav-link" href="/sign-in">تسجيل دخول</a></li>
                    <li className="nav-item"><a className="nav-link" href="/sign-up">إنشاء حساب</a></li>
                  </>}
              <li className="nav-item">
                <Link className="nav-link" to="/contact-us">تواصل معنا</Link>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/#featuresBox">المميزات</a>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/supplier">الموردون</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/about">من نحن</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" aria-current="page" to="/">الرئيسية</Link>
              </li>
            </ul>
          </div>
          <Link className="navbar-brand" href="/">
            <img src="./resources/premium_store.png" alt="" className="navbar-logo"/>
          </Link>
        </div>
      </nav>
    </div>
  )
}

export default Navbar;
