import { useState } from "react";
import { Link } from "react-router-dom";
import LoadingPatternOne from "../../dashboard/loadingpatterns/LoadingPatternOne";
import Swal from "sweetalert2";
import "./userslist.css";
import { api } from "../../../app/store";
import { useGetLocalUserData } from "../../../functions/useGetLocalUserData";
import useAuthChecker from "../useAuthChecker";

const UsersList = () => {
    const { userData } = useGetLocalUserData();
    useAuthChecker(userData);

    const { data: users, isLoading } = api.useGetUsersQuery();
    const [searchInput, setSearchInput] = useState("");

    const [deleteUserApi] = api.useDeleteUserMutation({
        fixedCacheKey: "users",
    });

    const deleteUser = (userId, element) => {
        Swal.fire({
            title: "تأكيد",
            text: "هل أنت متأكد من حذف المستخدم؟",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "نعم متأكد!",
        }).then((result) => {
            if (result.isConfirmed) {
                deleteUserApi(userId)
                    .unwrap()
                    .then((res) => {
                        if (res !== "user deleted") throw new Error("مشكلة");
                        Swal.fire("تم الحذف", "تم حذف المستخدم بنجاح", "success");
                    })
                    .catch((err) => {
                        Swal.fire(`${err}`, "حدث خطأ أثناء حذف المستخدم", "error");
                    });
                // fetch(`http://localhost:5000/users/${userId}`, {
                    //   method: "DELETE"
                    // })
                //   .then(res => {
                    //     if(!res.ok) {
                        //       throw Error("Cannot Delete User");
                        //     }
                    //     return res.json();
                    //   })
                //   .then(data => {
                    //     element.target.parentElement.parentElement.remove();
                    //     Swal.fire(
                        //       'تم الحذف',
                        //       'تم حذف المستخدم بنجاح',
                        //       'success'
                        //     )
                    //   })
                //   .catch(err => {
                    //     Swal.fire(
                        //       'مشكلة!',
                        //       'حدث خطأ أثناء حذف المستخدم',
                        //       'error'
                        //     )
                    //   });
            }
        });
    };

  if (isLoading) return <LoadingPatternOne />;
  return (
    users && (
      <div className="admin-users-page">
        <div className="container">
          <h1>Users</h1>
          <input
            type="search"
            placeholder="Search user by name"
            onChange={(e) => setSearchInput(e.target.value)}
            value={searchInput}
            className="form-control"
          />
          {users
            .filter((user) =>
              searchInput.length > 0
                ? user.username
                    .toLowerCase()
                    .includes(searchInput.toLowerCase())
                : true
            )
            .map((user) => (
              <div className="user-item" key={user._id}>
                <div className="info">
                  <h3>{user.username}</h3>
                  <span>{new Date(user.createdAt).toDateString()}</span>
                </div>
                <div className="buttons">
                  <button
                    onClick={(e) => deleteUser(user._id, e)}
                    className="btn btn-danger"
                  >
                    Delete user
                  </button>
                  <Link to={`/admin/users/view/${user._id}`}>
                    <button className="btn btn-success">View user</button>
                  </Link>
                </div>
              </div>
            ))}
        </div>
      </div>
    )
  );
};

export default UsersList;
