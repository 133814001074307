import { Link } from 'react-router-dom';
import './home.css';
const Header = () => {
    return (
        <header className="header">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 left-box">
                        <img src="./resources/shoppingcart.png" alt="" className="shoppingcart-image" />
                        <img src="./resources/shoppingcart.png" alt="" className="shoppingcart-image" />
                    </div>
                    <div className="col-md-6 right-box">
                        <h2 className="heading">طريقك الأسهل للتجارة الإلكترونية</h2>
                        <p className="">بادر بالبدء دون الحاجة إلى مخازن أو وسائل توصيل وبدون رأس مال</p>
                        <Link className="btn" to="/sign-up">قم بالانضمام</Link>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header;
