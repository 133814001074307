import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import PersonIcon from '@mui/icons-material/Person';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import Footer from '../../components/footer/Footer';
import Navbar from '../../components/navbar/Navbar';
import './contactus.css';
import Swal from 'sweetalert2';

const Contactus = () => {

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_0yac22e', 'template_stm7y7k', form.current, '8-0Skc2jvq0NHARRL')
            .then((result) => {
                console.log(result);
                const items = document.querySelectorAll('.form-control');
                console.log([...items])
                if (items) [...items].map(e => e.value = '');
                Swal.fire('تم الارسال!', 'تم ارسال رسالتك بنجاح!', 'success');
            }, (error) => {
                Swal.fire('Error!', 'حدث خطأ ما!', 'error');
            });
    };

    return (
        <div className="contact-us-page">
            <Navbar />
            <div className="container">
                <form ref={form} onSubmit={sendEmail}>
                    <h3>تواصل معنا</h3>
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1">@</span>
                        <input name="email" type="text" className="form-control" placeholder="البريد اللاكتروني" aria-label="Emial" aria-describedby="basic-addon1" required />
                    </div>
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1"><PersonIcon /></span>
                        <input name="name" type="text" className="form-control" placeholder="اسمك" aria-label="Username" aria-describedby="basic-addon1" required />
                    </div>
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1"><LocalPhoneIcon /></span>
                        <input name="phone_number" type="text" className="form-control" placeholder="رقم الهاتف" aria-label="PhoneNumber" aria-describedby="basic-addon1" required />
                    </div>
                    <div className="input-group">
                        <span className="input-group-text">رسالتك</span>
                        <textarea name="message" className="form-control" aria-label="With textarea" required></textarea>
                    </div>
                    <button type="submit" className="btn btn-success">إرسال</button>
                </form>
            </div>
            <Footer />
        </div>
    )
}

export default Contactus
