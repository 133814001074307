import Swal from "sweetalert2";
import { api } from "../app/store";

export function useRemoveProductFromFav(productId) {
  const [ removeFromFav ] = api.useRemoveProductFromFavoriteMutation({
    fixedCacheKey: "favorite-products"
  });

  return () => removeFromFav({
    productId
  })
    .unwrap()
    .then(res => {
      if(res !== "Removed from favorite") throw Error("مشكلة!");
    })
    .catch(err => {
      Swal.fire({
        icon: "error",
        html: "حدث خطأ أثناء إزالة المنتج!",
        title: `${err}`
      });
    })

}