import Carousel from 'react-bootstrap/Carousel';
import './carousel.css';

function ProductCarousel({productData}) {
  return (
    <div className="carousel-container">
      <Carousel>
        {productData.pictures.map((picture, index) => (
          <Carousel.Item key={index}>
              <div style={{background: `url(${picture.pic_url}) center/contain no-repeat`}} className="img d-block w-100"></div>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
}

export default ProductCarousel;
