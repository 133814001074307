import { forwardRef, useMemo } from 'react'
import { Link } from 'react-router-dom';
import { useAddProductToFav } from '../../../functions/useAddProductToFav';
import { useRemoveProductFromFav } from '../../../functions/useRemoveProductFromFav';

const ProductItemSkeleton = (props, ref) => {
  
  const product = useMemo(
    () => props.productData ? JSON.parse(props.productData) : {},
    [props.productData]
  );

  const favoriteProducts = useMemo(
    () => props.favProducts ? JSON.parse(props.favProducts) : [],
    [props.favProducts]
  );

  const addToFav = useAddProductToFav(product._id);
  const removeFromFav = useRemoveProductFromFav(product._id);

  return (
    <div ref={ref} key={product._id} className="product-item">
      <div className="img-container">
        <img src={product.pictures[0].pic_url} alt="" />
      </div>
      <div className="product-info">
        <div>
          <Link to={`/dashboard/catalog/details/${product._id}`}><h6>{product.name}</h6></Link>
          <p className="price">الثمن {product.price} د.أ</p>
          <p className="suggested-price">ثمن البيع المقترح {product.suggested_price} د.أ</p>
        </div>
        <div className="buttons">
          {favoriteProducts.some(element => element.product_id === product._id) 
          ?
            <button className="card-btn" onClick={e => {
                removeFromFav();
                e.target.innerHTML = 'اضف إلى المفضلة';
              }}>إزالة من المفضلة</button>
          :
              <button className="card-btn" onClick={e => {
                addToFav();
                e.target.innerHTML = 'إزالة من المفضلة';
              }}>أضف إلى المفضلة</button>
          }
        </div>
      </div>
    </div>
  )
}

const ProductItem = forwardRef(ProductItemSkeleton);

export default ProductItem;
