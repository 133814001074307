import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { connnection } from "../shared/connection";

export const api = createApi({
    reducerPath: "api",
    baseQuery: fetchBaseQuery({ baseUrl: connnection }),
    tagTypes: ["Users", "LocalUser", "Order", "Products", "WithdrawOrders"],
    endpoints: (builder) => ({
        createUser: builder.mutation({
            query: (userData) => ({
                url: `/users/signup`,
                method: "POST",
                body: userData,
            }),
        }),
        updateUser: builder.mutation({
            query: (updatedData) => ({
                url: `/users/profile/update-basic/${sessionStorage.getItem("userId")}`,
                method: "POST",
                body: updatedData,
            }),
            invalidatesTags: (result, error, arg) => ["LocalUser"],
        }),
        deleteUser: builder.mutation({
            query: (userId) => ({
                url: `/users/${userId}`,
                method: "DELETE",
            }),
            invalidatesTags: (result, error, arg) => ["Users"],
        }),
        getUser: builder.query({
            query: (userId) => `/users/${userId}`,
            providesTags: (result, error, arg) => ["LocalUser"],
        }),
        getUsers: builder.query({
            query: () => `/users`,
            providesTags: ["Users"],
        }),
        pushNotificationToAllUsers: builder.mutation({
            query: (notification) => ({
                url: "/users/notification",
                method: "POST",
                body: notification,
            }),
        }),
        pushNotificationToUser: builder.mutation({
            query: (notifcationBody) => ({
                url: `/users/notification/${notifcationBody.userId}`,
                method: "POST",
                body: notifcationBody.data,
            }),
        }),
        deleteNotification: builder.mutation({
            query: (notificationId) => ({
                url: `/users/notification/${sessionStorage.getItem(
                    "userId"
                )}`,
                method: "DELETE",
                body: notificationId,
            }),
            invalidatesTags: (result, error, arg) => ["LocalUser"],
        }),
        getNotifications: builder.query({
            query: (userId) => `/users/notification/${userId}`,
            providesTags: ["LocalUser"],
        }),
        withdrawMoney: builder.mutation({
            query: ({userId}) => ({
                url: `/users/withdraw/${userId}`,
                method: "POST"
            }),
            invalidatesTags: (result, error, args) => ["LocalUser"]
        }),
        createProduct: builder.mutation({
            query: (productData) => ({
                url: "/products/add",
                method: "POST",
                body: productData,
            }),
            invalidatesTags: (result, error, arg) => ["Products"],
        }),
        editProduct: builder.mutation({
            query: (requestData) => ({
                url: `/products/edit/${requestData.productId}`,
                method: "POST",
                body: requestData,
            }),
            invalidatesTags: (result, error, arg) => ["Products"],
        }),
        deleteProduct: builder.mutation({
            query: (productId) => ({
                url: `/products/${productId}`,
                method: "DELETE",
            }),
            invalidatesTags: (result, error, arg) => ["Products"],
        }),
        getProducts: builder.query({
            query: () => "/products",
            providesTags: ["Products"],
        }),
        getProductById: builder.query({
            query: (prodId) => `/products/${prodId}`,
            providesTags: ["Products"],
        }),
        getPaginatedProducts: builder.query({
            query: (page) => `/products/get-items?page=${page}`,
            providesTags: ["Products"],
        }),
        addProductToCart: builder.mutation({
            query: (products) => ({
                url: `users/add-to-cart/${sessionStorage.getItem("userId")}`,
                method: "POST",
                body: products,
            }),
            invalidatesTags: (result, error, arg) => ["LocalUser"],
        }),
        removeProductFromCart: builder.mutation({
            query: (prodId) => ({
                url: `users/remove-from-cart/${sessionStorage.getItem("userId")}`,
                method: "POST",
                body: prodId,
            }),
            invalidatesTags: (result, error, arg) => ["LocalUser"],
        }),
        clearCart: builder.mutation({
            query: () => ({
                url: `/users/clear-shopping-cart/${sessionStorage.getItem("userId")}`,
                method: "POST",
            }),
            invalidatesTags: (result, error, arg) => ["LocalUser"],
        }),
        addProductToFavorite: builder.mutation({
            query: (prodId) => ({
                url: `/users/add-to-fav/${sessionStorage.getItem("userId")}`,
                method: "POST",
                body: prodId,
            }),
            invalidatesTags: (result, error, arg) => ["LocalUser"],
        }),
        removeProductFromFavorite: builder.mutation({
            query: (prodId) => ({
                url: `/users/remove-from-favorite/${sessionStorage.getItem("userId")}`,
                method: "POST",
                body: prodId,
            }),
            invalidatesTags: (result, error, arg) => ["LocalUser"],
        }),
        createOrder: builder.mutation({
            query: (orderData) => ({
                url: "/orders/new",
                method: "post",
                body: orderData,
            }),
            invalidatesTags: (result, error, arg) => ["Order", "LocalUser"],
        }),
        changeOrderState: builder.mutation({
            query: (reqData) => ({
                url: `/orders/${reqData.orderId}`,
                method: "POST",
                body: reqData.body,
            }),
            invalidatesTags: (result, error, arg) => ["Order"],
        }),
        cancelOrder: builder.mutation({
            query: (reqData) => ({
                url: `/orders/cancel/${reqData.orderId}`,
                method: "POST",
            }),
            invalidatesTags: (result, error, arg) => ["Order"],
        }),
        incrementDeliveredOrders: builder.mutation({
            query: (reqData) => ({
                url: `/users/delivered-order/${reqData.sellerId}`,
                method: "POST",
                body: reqData.body,
            }),
        }),
        incrementRevenueByOrder: builder.mutation({
            query: (reqData) => ({
                url: `/users/revenue/${reqData.sellerId}`,
                method: "POST",
                body: reqData.body,
            }),
        }),
        getOrderById: builder.query({
            query: (orderId) => `orders/${orderId}`,
            providesTags: ["Order"],
        }),
        getOrders: builder.query({
            query: () => `/orders`,
            providesTags: ["Order"],
        }),
        getOrdersForUser: builder.query({
            query: (userId) => `orders/get-users-orders/${userId}`,
            providesTags: ["Order"],
        }),
        getWithdrawOrders: builder.query({
            query: () => `/withdraw-orders`,
            providesTags: ["WithdrawOrders"],
        }),
        approveWithdrawOrder: builder.mutation({
            query: ({orderId}) => ({
                url: `/withdraw-orders/approve/${orderId}`,
                method: "POST",
            }),
            invalidatesTags: (result, error, arg) => ["WithdrawOrders", "Users", "LocalUser"],
        }),
        rejectWithdrawOrder: builder.mutation({
            query: ({orderId}) => ({
                url: `/withdraw-orders/reject/${orderId}`,
                method: "POST",
            }),
            invalidatesTags: (result, error, arg) => ["WithdrawOrders", "Users", "LocalUser"],
        })
    }),
});
