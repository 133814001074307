import { useState } from 'react';
import LoadingPatternOne from '../../dashboard/loadingpatterns/LoadingPatternOne'
import OrderItem from './orderitem/OrderItem';
import './ordersmain.css';
import { api } from '../../../app/store';
import { useGetLocalUserData } from '../../../functions/useGetLocalUserData';
import useAuthChecker from '../useAuthChecker';

const OrdersMain = () => {

    const { userData } = useGetLocalUserData();
    useAuthChecker(userData);

    const [filter, setFilter] = useState('الكل');
    const { data: orders, isLoading } = api.useGetOrdersQuery();

    const handleFilter = e => {
        setFilter(e.target.value);
    };

    if (isLoading) {
        return <LoadingPatternOne />
    }
    return (orders &&
        <div className="admin-orders-page">
            <div className="container">
                <h1>Orders</h1>
                <select onChange={handleFilter} value={filter} className="form-select">
                    <option value="الكل">الكل</option>
                    <option value="معلقة">معلقة</option>
                    <option value="قيد التسليم">قيد التسليم</option>
                    <option value="مسلمة">مسلمة</option>
                    <option value="ملغية">ملغية</option>
                </select>
                <table className="table" dir="rtl">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">التاريخ</th>
                            <th scope="col">الإجمالي</th>
                            <th scope="col">عدد المنتجات</th>
                            <th scope="col">الحالة</th>
                            <th scope="col">الإجراءات</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orders
                            .filter((order) => filter === 'الكل' || order.order_state === filter)
                            .map((order, index) => {
                                return <OrderItem
                                    key={order._id}
                                    index={index + 1}
                                    id={order._id}
                                    total={order.taken_from_client}
                                    date={order.createdAt}
                                    quantity={order.products.length}
                                    state={order.order_state}
                                />
                            })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default OrdersMain
