import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { Check } from "@mui/icons-material";
import { api } from "../../../../app/store";

const determineColor = (state) => {
  switch (state) {
    case "pending":
      return "table-warning";
    case "approved":
      return "table-success";
    case "rejected":
      return "table-danger";
    default:
      return "";
  }
};

const OrderItem = ({ id, state, userId, index, amount, date }) => {
    const [reject] = api.useRejectWithdrawOrderMutation({
        fixedCacheKey: "withdraw-orders",
    });

    const [approve] = api.useApproveWithdrawOrderMutation({
        fixedCacheKey: "withdraw-orders",
    });

    const { data: user } = api.useGetUserQuery(userId);

    console.log(user);
    console.log(id);

  return (user &&
    <tr className={determineColor(state)}>
      <th scope="row">{index}</th>
      <td>{user.username}</td>
      <td>{amount} د.أ</td>
      <td>{new Date(date).toDateString()}</td>
      <td>{user.cliqAlias}</td>
      <td>
        {state === "pending" ? (
            <>
            <CloseIcon
            onClick={() => {
                reject({ orderId: id })
                    .unwrap()
                    .then((res) => {
                        if (res !== "Order Rejected") throw new Error("مشكلة");
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }}
            style={{
                color: "red",
                    cursor: "pointer",
                    marginLeft: 10,
            }}
            />
            <Check
            onClick={() => {
                approve({orderId: id})
                    .unwrap()
                    .then((res) => {
                        if (res !== "Order Approved") throw new Error("مشكلة");
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }}
            style={{
                color: "blue",
                    cursor: "pointer",
                    marginLeft: 10,
            }}
            />
            </>
        ): <span>لا يوجد</span>}
      </td>
    </tr>
  );
};

export default OrderItem;
