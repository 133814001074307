import { useContext } from "react";
import Swal from "sweetalert2";
import { ProductsContext } from "../ShoppingCart";
import "./orderform.css";
import { api } from "../../../../app/store";

const OrderForm = ({ totalPrice, totalCost, setTotalPrice, setTotalCost }) => {
  const productsContext = useContext(ProductsContext);

  const [createOrder] = api.useCreateOrderMutation({
    fixedCacheKey: "orders",
  });

  const [clearShoppingCart] = api.useClearCartMutation({
    fixedCacheKey: "shopping-cart-products",
  });

    const submitOrder = () => {
        const name = document.getElementById("customerName").value;
        const city = document.getElementById("region").value;
        const client_phone = document.getElementById("phoneNumber").value;
        const from_store = document.getElementById("shopName").value;
        const detailed_address = document.getElementById("customerAddress").value;

        if (
            name.trim() === "" ||
            city.trim() === "" ||
            client_phone.trim() === "" ||
            from_store.trim() === "" ||
            detailed_address.trim() === ""
        ) {
            Swal.fire({
                title: "الرجاء تعبئة تفاصيل الشحن",
                icon: "error",
            });
            console.error("You can't send empty data");
            return;
        }
        const customerData = {
            name,
            city,
            client_phone,
            from_store,
            detailed_address,
        };

        createOrder({
            total_products_cost: totalCost,
            taken_from_client: totalPrice + 2.75,
            total_profit: totalPrice - totalCost - 1,
            sellerId: sessionStorage.getItem("userId"),
            products: productsContext.current,
            client: customerData,
        })
            .unwrap()
            .then((res) => {
                if (res !== "Order Created") throw new Error("مشكلة");
                document.getElementById("customerName").value = "";
                document.getElementById("phoneNumber").value = "";
                document.getElementById("shopName").value = "";
                document.getElementById("customerAddress").value = "";

                Swal.fire({
                    title: "تم إرسال الطلب",
                    icon: "success",
                });

                clearShoppingCart();
                setTotalPrice(0);
                setTotalCost(0);
            })
            .catch((err) => {
                Swal.fire({
                    icon: "error",
                    title: `${err}`,
                    html: "حدثت مشكلة أثناء إنشاء الطلب!",
                });
            });
    };

  return (
    <>
      <div className="order-submission">
        <div className="order-details">
          <h2>ملخص الطلب</h2>
          <div className="prices-details">
            <div className="three-senteces">
              <p>
                <span>إجمالي سعر تكلفة المنتجات</span>{" "}
                <span>{totalCost} د.أ</span>
              </p>
              <p>
                <span>تكلفة شحن الطلب</span>
                <span>2.75 د.أ</span>
              </p>
              <p>
                <span>إجمالي التكلفة</span>
                <span>{totalCost + 2.75} د.أ</span>
              </p>
            </div>
            <div className="paid-from-customer">
              <p>
                المبلغ المراد تحصيله من العميل <small>(شامل التوصيل)</small>
              </p>
              <h3>{totalPrice + 2.75} د.أ</h3>
            </div>
          </div>
          <hr />
          <div className="profit-details">
            <p>
              <span>تكلفة تجهيز الطلب</span>
              <span>1 د.أ</span>
            </p>
            <p>
              <span>إجمالي الربح من الطلب</span>
              <span>
                {totalPrice !== 0 && totalCost !== 0
                  ? totalPrice - totalCost - 1
                  : 0}{" "}
                د.أ
              </span>
            </p>
            <p>
              <span>مدة التوصيل</span>
              <span>3-1 أيام عمل</span>
            </p>
          </div>
          <div className="button-container">
            <button className="btn" onClick={submitOrder}>
              اطلب الان!
            </button>
          </div>
        </div>
        <div className="order-form">
          <h2>تفاصيل الشحن</h2>
          <form>
            <div className="form-group">
              <label htmlFor="customerName">الاسم</label>
              <input
                type="text"
                className="form-control"
                id="customerName"
                placeholder="اسم المشتري رباعيا"
              />
            </div>
            <div className="form-group">
              <label htmlFor="region">المحافظة</label>
              <select className="form-control" id="region">
                <option value="amman">عمان</option>
                <option value="balqa">البلقاء</option>
                <option value="jarash">جرش</option>
                <option value="zarqa">الزرقاء</option>
                <option value="irbid">إربد</option>
                <option value="tafileh">الطفيلة</option>
                <option value="ajloon">عجلون</option>
                <option value="aqaba">العقبة</option>
                <option value="karak">الكرك</option>
                <option value="madaba">مادبا</option>
                <option value="maan">معان</option>
                <option value="mafrak">المفرق</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="phoneNumber">رقم الهاتف</label>
              <input
                type="text"
                className="form-control"
                id="phoneNumber"
                placeholder="07********"
              />
            </div>
            <div className="form-group">
              <label htmlFor="shopName">اسم متجرك / صفحتك</label>
              <input
                type="text"
                className="form-control"
                id="shopName"
                placeholder="اسم المتجر الخاص بك أو صفحتك على أحد مواقع التواصل الاجتماعي"
              />
            </div>
            <div className="form-group">
              <label htmlFor="customerAddress">العنوان</label>
              <textarea
                className="form-control"
                placeholder="كلما كان الموقع أكثر دقة كان ذلك أفضل"
                id="customerAddress"
                rows="3"
              ></textarea>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default OrderForm;
