import TotalAvailableBalance from '../components/totalabailablebalance/TotalAvailableBalance';
import QuickStats from '../components/quickstats/QuickStats';
import './main.css';
import { useGetLocalUserData } from '../../../functions/useGetLocalUserData'
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

const Main = () => {
  const { userData } = useGetLocalUserData();
  const totalSelling = useMemo(
    () => userData.prizes_rev + userData.sellings_rev,
    [userData]
  )
  return (
    <div className="dashboard">
      <div className="row-dashoard row">
        <div className="help">
          <h1>الدعم</h1>
          <p>premiumstoreconnect@gmail.com: البريد الاكتروني</p>
          <div className="support-button-container">
            <Link to="/dashboard/guide" className="btn btn-danger">دليل المستخدم</Link>
          </div>
        </div>
        {userData && <TotalAvailableBalance balance={userData.prizes_rev + userData.sellings_rev} />}
      </div>
      <div className="row-dashoard row">
        {userData && <QuickStats
          ordersPerMonth={(userData.delivered_orders || []).filter(order => new Date(order.createdAt).getMonth() === new Date().getMonth()).length}
          totalDeliveredOrders={(userData.delivered_orders || []).length}
          totalSellings={Number.isNaN(totalSelling) ? "Loading" : totalSelling}
        />}
      </div>
    </div>
  )
}

export default Main
