import './favoriteproducts.css';
import Accordion from 'react-bootstrap/Accordion';
import LoadingPatternOne from '../loadingpatterns/LoadingPatternOne';
import { useGetLocalUserData } from '../../../functions/useGetLocalUserData';
import { api } from '../../../app/store';
import AccordionItem from './AccordionItem';

const FavoriteProducts = () => {

  const { userData, isLoading } = useGetLocalUserData();
  const { data: products, isLoading: isPenignProducts} = api.useGetProductsQuery();

  return (
    <div className="favorite-products-page">
      {(isLoading || isPenignProducts) && <LoadingPatternOne />}
      {(userData && products) &&
      <div className="row-dashoard row">
        <h1 align="right" style={{paddingBottom: 20}}>المنتجات المفضلة</h1>
        <Accordion>
          {userData.favorite_products && userData.favorite_products.map(({product_id}) => {
            const productItem = products.filter(product => product._id === product_id)[0];
            return (
              <Accordion.Item key={product_id} eventKey={product_id}>
                <AccordionItem
                  product={JSON.stringify(productItem)}
                />
              </Accordion.Item>
            )
          })}
        </Accordion>
      </div>
      }
    </div>
  )
}

export default FavoriteProducts
