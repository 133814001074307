import Accordion from "react-bootstrap/Accordion";
import { useRemoveProductFromFav } from "../../../functions/useRemoveProductFromFav";
import { useAddProductToCart } from "../../../functions/useAddProductToCart";

const AccordionItem = ({ product }) => {
  const productItem = JSON.parse(product);
  
  const addToCart = useAddProductToCart(productItem._id, productItem.suggested_price);
  const removeFromFav = useRemoveProductFromFav(productItem._id)

  return (
    <>
      <Accordion.Header>{productItem.name}</Accordion.Header>
      <Accordion.Body>
        <div className="description-box">
          {productItem.description}
        </div>
        <div className="buttons">
          <button className="btn btn-danger" style={{marginRight: 5}} onClick={(e) => {
            removeFromFav()
          }}>إزالة من منتجاتي المفضلة</button>
          <button className="btn btn-info" onClick={e => {
            addToCart();
          }}>
            إضافة إلى السلة
          </button>
        </div>
      </Accordion.Body>
    </>
  )
}

export default AccordionItem;
