import { useParams } from 'react-router-dom';
import DashboardNavbar from '../dashboardNavbar/DashboardNavbar';
import Sidebar from '../sidebar/Sidebar';
import './orderdetails.css';
import SupplierData from './SupplierData';
import LoadingPatternOne from '../loadingpatterns/LoadingPatternOne';
import changeOrderState from '../../../functions/changeOrderState';
import ProductDetails from './ProductDetails';
import ShippingDetails from './ShippingDetails';
import OrderSummary from './OrderSummary';
import { useGetLocalUserData } from '../../../functions/useGetLocalUserData';
import { api } from '../../../app/store';

const OrderDetails = () => {
  const { id: orderId } = useParams();
  const { data: orderData, isLoading: isLoadingOrder } = api.useGetOrderByIdQuery(orderId);
  const { userData, isLoading: isLoadingUser } = useGetLocalUserData();
  const [cancelOrder] = api.useCancelOrderMutation();

  return (
    <div className="order-details-page">
      {(isLoadingOrder || isLoadingUser) && <LoadingPatternOne />}
      {(userData && orderData) &&
      <div className="row-dashoard row">
        <div className="flex-container">
          <div className="cancel-order">
            <div className="button-container">
              {orderData.order_state === "ملغية"
                ? <input type="button" value="ملغي" className="btn btn-disabled" />
                : <button className='btn' onClick={() => {
                    cancelOrder({ orderId });
                    window.location.reload();
                  }}>إلغاء الطلب</button>}
            </div>
          </div>
          <SupplierData supplierName={userData.username} creationDate={orderData.createdAt} orderState={orderData.order_state} />
        </div>
        <div className="products-details-container">
          <table dir="rtl" className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">صورة</th>
                <th scope="col">الاسم</th>
                <th scope="col">سعر التكلفة</th>
                <th scope="col">الكمية</th>
                <th scope="col">سعر البيع</th>
              </tr>
            </thead>
            <tbody>
              {orderData.products.map((product, index) => {
                return <ProductDetails
                  key={product.productId}
                  quantity={product.quantity}
                  number={index+1}
                  productId={product.productId}
                  sellingPrice={product.sellingPrice}
                />
              })}
            </tbody>
          </table>
        </div>
        <div className="flex-container">
          <OrderSummary order={JSON.stringify(orderData)} />
          <ShippingDetails order={JSON.stringify(orderData)} />
        </div>
      </div>}
    </div>
  )
}

export default OrderDetails
