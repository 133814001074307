import { useGetLocalUserData } from '../../../functions/useGetLocalUserData';
import LoadingPatternOne from '../loadingpatterns/LoadingPatternOne';
import TotalAvailableBalance from '../components/totalabailablebalance/TotalAvailableBalance';
import './wallet.css';
import { api } from '../../../app/store';
import Swal from 'sweetalert2';

const Wallet = () => {
    const { userData, isLoading } = useGetLocalUserData();
    const [withdrawMoney] = api.useWithdrawMoneyMutation({
        fixedCacheKey: "withdraw-orders",
    });

    const handleWithdraw = () => {
        if(userData && userData.prizes_rev + userData.sellings_rev < 6) {
            Swal.fire("مشكلة!", "لا يمكن سحب أقل من 6 د.ا", "error");
            return;
        }

        withdrawMoney({userId: userData._id})
            .unwrap()
            .then((res) => {
                if(res.status === 400) {
                    throw new Error("الرجاء التأكد من شروط التحويل");
                }

                Swal.fire({
                    icon: 'success',
                    title: 'تم إرسال طلب سحب رصيد',
                });
            })
            .catch((err) => {
                Swal.fire("مشكلة!", `${err}`, "error");
            });
    };

    return (
        <>
            {isLoading && <LoadingPatternOne />}
            <div className="wallet">
                <div className="container">
                    <div className="row-dashoard row">
                        <h1>المحفظة المالية</h1>
                        <TotalAvailableBalance balance={userData.prizes_rev + userData.sellings_rev} />
                        <div className="cash-out">
                            <h4>سحب رصيد</h4>
                            <p>أقل مبلغ للسحب هو 6 دأ</p>
                            <button className="btn btn-success" onClick={handleWithdraw}>سحب</button>
                        </div>
                        <div className="personal-info"> {/* Flex */}
                            <div className="text-data">
                                <div className="name">{userData.username} الاسم</div>
                                <div className="phone-number">{userData.phone_number} رقم الهاتف </div>
                                <div className="email">{userData.email} البريد الالكتروني </div>
                                <div className="total-cash">
                                    <h4>إجمالي المبالغ المسحوبة</h4>
                                    <p>{userData.total_withdrawed || 0} دأ</p>
                                </div>
                            </div>
                            <img src={userData.profile_picture || "https://st3.depositphotos.com/1767687/16607/v/450/depositphotos_166074422-stock-illustration-default-avatar-profile-icon-grey.jpg"} alt="" />
                        </div>
                        <div className="transactions">
                            <h6>المعاملات الحسابية</h6>
                            <p>يتم إظهار جميع المعاملات الحسابية هنا</p>
                            <h4>لا يوجد معاملات مالية</h4>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Wallet
