import Swal from "sweetalert2";
import { api } from "../app/store";

export function useAddProductToFav(productId) {
  const [addToFav] = api.useAddProductToFavoriteMutation({
    fixedCacheKey: "favorite-products"
  });

  return () => addToFav({
    productId
  })
    .unwrap()
    .then(res => {
      if(res !== "Added to favorite") throw Error("مشكلة!");
    })
    .catch(err => {
      Swal.fire({
        icon: "error",
        html: "حدث خطأ أثناء إضافة المنتج!",
        title: `${err}`
      });
    });
}