/*
 *  @TODO: Add Shop Links Section for Shopify, facebook and instagram shops
 *  @TODO: Add Reset passwor section
 *  @TODO: Finish the profile picture upload functionality
 */

import LoadingPatternOne from "../loadingpatterns/LoadingPatternOne";
import { Link, Navigate } from "react-router-dom";
import DashboardNavbar from "../dashboardNavbar/DashboardNavbar";
import Sidebar from "../sidebar/Sidebar";
import "./profile.css";
import { useState } from "react";
import Swal from "sweetalert2";
import { useGetLocalUserData } from "../../../functions/useGetLocalUserData";
import { api } from "../../../app/store";

const Profile = () => {
    if (!sessionStorage.getItem("userId")) {
        Navigate({ to: "/" });
    }

    const [updateUser] = api.useUpdateUserMutation({
        fixedCacheKey: "local-user",
    });

    const [profilePic, setProfilePic] = useState();
    const { userData, isLoading } = useGetLocalUserData();
    const updateBasicData = (e) => {
        e.preventDefault();

        updateUser({
            address: document.getElementById("addressField").value,
            postal_code: document.getElementById("postalCode").value,
            company: document.getElementById("company").value,
            cliqAlias: document.getElementById("cliqAlias").value,
        })
            .unwrap()
            .then((res) => {
                if (res !== "User updated") throw new Error("مشكلة!");
                Swal.fire("تمت العملية", "تم تعديل بياناتك بنجاح!", "success");
            })
            .catch((err) => {
                Swal.fire("مشكلة", `${err}`, "error");
            });
    };

    const handlePictureChange = (e) => {
        if (e.target.files) {
            setProfilePic(e.target.files[0]);
        }
    };

    const handlePictureUpload = (e) => {
        e.preventDefault();

        const formData = new FormData(e.target);

        fetch("http://localhost:5000/users/profile/picture/" + sessionStorage.getItem("userId"), {
            method: "POST",
            body: formData
        });

    };

    return (
        <>
            {isLoading && <LoadingPatternOne />}
            <div className="profile">
                <div className="row-dashoard row">
                    <h1>الحساب الشخصي</h1>
                    <div className="col-md-6 text-data">
                        <form onSubmit={updateBasicData}>
                            <div className="input-group mb-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    readOnly
                                    dir="rtl"
                                    value={userData.username}
                                    aria-label="Username"
                                    aria-describedby="basic-addon2"
                                />
                                <span className="input-group-text" id="username">
                                    اسم الحساب
                                </span>
                            </div>
                            <div className="input-group mb-3">
                                <input
                                    type="text"
                                    id="addressField"
                                    className="form-control"
                                    dir="rtl"
                                    defaultValue={userData.address || ""}
                                    aria-label="Username"
                                    aria-describedby="basic-addon2"
                                />
                                <span className="input-group-text" id="address">
                                    العنوان
                                </span>
                            </div>
                            <div className="input-group mb-3">
                                <input
                                    type="text"
                                    id="postalCode"
                                    className="form-control"
                                    dir="rtl"
                                    defaultValue={userData.postal_code || ""}
                                    aria-label="Username"
                                    aria-describedby="basic-addon2"
                                />
                                <span className="input-group-text" id="postalCode">
                                    الرمز البريدي
                                </span>
                            </div>
                            <div className="input-group mb-3">
                                <input
                                    type="text"
                                    id="company"
                                    className="form-control"
                                    dir="rtl"
                                    defaultValue={userData.company || ""}
                                    aria-label="Username"
                                    aria-describedby="basic-addon2"
                                />
                                <span className="input-group-text" id="company">
                                    الشركة
                                </span>
                            </div>
                            <div className="input-group mb-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    dir="rtl"
                                    readOnly
                                    value={userData.email}
                                    aria-label="Username"
                                    aria-describedby="basic-addon2"
                                />
                                <span className="input-group-text" id="email">
                                    البريد الالكتروني
                                </span>
                            </div>
                            <div className="input-group mb-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    dir="rtl"
                                    readOnly
                                    value={userData.phone_number}
                                    aria-label="Username"
                                    aria-describedby="basic-addon2"
                                />
                                <span className="input-group-text" id="phoneNumber">
                                    رقم الهاتف
                                </span>
                            </div>
                            <div className="input-group mb-3">
                                <input
                                    type="text"
                                    id="cliqAlias"
                                    className="form-control"
                                    dir="rtl"
                                    defaultValue={userData.cliqAlias || ""}
                                    aria-label="Username"
                                    aria-describedby="basic-addon2"
                                />
                                <span className="input-group-text" id="address">
                                    عنوان كليك (alias)
                                </span>
                            </div>
                            <div dir="rtl" className="input-group mb-3">
                                <input
                                    className="btn btn-danger"
                                    type="submit"
                                    value="تعديل الحساب"
                                />
                            </div>
                        </form>
                    </div>
                    <div className="col-md-6 image-data">
                        <div className="overlay">
                            <img
                                src={
                                    userData.profile_picture ||
                                    "https://st3.depositphotos.com/1767687/16607/v/450/depositphotos_166074422-stock-illustration-default-avatar-profile-icon-grey.jpg"
                                }
                                alt=""
                            />
                            <form
                                id="formPicture"
                                encType="multipart/form-data"
                                onSubmit={handlePictureUpload}
                            >
                                <input
                                    onChange={handlePictureChange}
                                    name="profile_picture"
                                    type="file"
                                    id="fileField"
                                />
                                <input
                                    type="submit"
                                    value="Upload"
                                    className="btn btn-primary"
                                />
                            </form>
                        </div>
                    </div>
                </div>
                {userData.isAdmin && (
                    <div className="row-dashoard row" style={{ textAlign: "center" }}>
                        <Link to="/admin">
                            <button className="btn btn-warning">Admins</button>
                        </Link>
                    </div>
                )}
            </div>
        </>
    );
};

export default Profile;
