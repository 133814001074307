import { useState } from "react";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import NotificationsIcon from "@mui/icons-material/Notifications";
import DeleteIcon from "@mui/icons-material/Delete";
import "./dashboardnavbar.css";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useGetLocalUserNotifications } from "../../../functions/useGetLocalUserNotification";
import { api } from "../../../app/store";

const DashboardNavbar = () => {
  const signOut = () => {
    sessionStorage.removeItem("userId");
  };

  const [showNotifications, setShowNotifications] = useState(false);

  const [deleteNotificationApi] = api.useDeleteNotificationMutation({
    fixedCacheKey: "local-user",
  });

  const { notifications, isLoading } = useGetLocalUserNotifications();

  const deleteNotification = (notificationId) => {
    deleteNotificationApi({ notification_id: notificationId })
      .unwrap()
      .then((res) => {
        if (res !== "Deleted Notification") throw new Error("مشكلة!");
      })
      .catch((err) => {
        Swal.fire("مشكلة!", `${err}`, "error");
      });
  };
  if (sessionStorage.getItem("userId")) {
    return (
      <div className="dashboard-navbar">
        <div
          className="notification"
          id="notification"
          onClick={() => setShowNotifications(!showNotifications)}
        >
          <NotificationsIcon className="notification-icon" />
          {showNotifications && (
            <div className="notification-list" id="notificationList">
              {isLoading && <>Loading...</>}
              {notifications.length === 0 && !isLoading ? (
                <>لا يوجد لديك إشعارات</>
              ) : (
                <ul>
                  {notifications.map((msg) => (
                    <li>
                      <div className="left">
                        <button
                          onClick={() => {
                            deleteNotification(msg._id);
                          }}
                        >
                          <DeleteIcon className="delete-notification-icon icon" />
                        </button>
                      </div>
                      <div className="right">
                        <h5>{msg.title}</h5>
                        <p>{msg.body}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}
        </div>
        <div className="navbar-buttons">
          <a href="/" onClick={signOut}>
            <div className="navbar-button">تسجيل الخروج</div>
          </a>
          <Link to="/dashboard/shopping-cart">
            <div className="navbar-button">
              <ShoppingCartIcon className="icon" />
              سلة المشتريات
            </div>
          </Link>
        </div>
      </div>
    );
  } else {
    window.location.pathname = "/";
  }
};

export const LayoutDashboardNavbar = () => {
  const signOut = () => {
    sessionStorage.removeItem("userId");
  };

  const [showNotifications, setShowNotifications] = useState(false);

  const [deleteNotificationApi] = api.useDeleteNotificationMutation({
    fixedCacheKey: "local-user",
  });

  const { notifications, isLoading } = useGetLocalUserNotifications();

  const deleteNotification = (notificationId) => {
    deleteNotificationApi({ notification_id: notificationId })
      .unwrap()
      .then((res) => {
        if (res !== "Deleted Notification") throw new Error("مشكلة!");
      })
      .catch((err) => {
        Swal.fire("مشكلة!", `${err}`, "error");
      });
  };
  if (sessionStorage.getItem("userId")) {
    return (
      <div className="layout-dashboard-navbar">
        <div
          className="notification"
          id="notification"
          onClick={() => setShowNotifications(!showNotifications)}
        >
          <NotificationsIcon className="notification-icon" />
          {showNotifications && (
            <div className="notification-list" id="notificationList">
              {isLoading && <>Loading...</>}
              {notifications.length === 0 && !isLoading ? (
                <>لا يوجد لديك إشعارات</>
              ) : (
                <ul>
                  {notifications.map((msg) => (
                    <li>
                      <div className="left">
                        <button
                          onClick={() => {
                            deleteNotification(msg._id);
                          }}
                        >
                          <DeleteIcon className="delete-notification-icon icon" />
                        </button>
                      </div>
                      <div className="right">
                        <h5>{msg.title}</h5>
                        <p>{msg.body}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}
        </div>
        <div className="navbar-buttons">
          <a href="/" onClick={signOut}>
            <div className="navbar-button">تسجيل الخروج</div>
          </a>
          <Link to="/dashboard/shopping-cart">
            <div className="navbar-button">
              <ShoppingCartIcon className="icon" />
              سلة المشتريات
            </div>
          </Link>
        </div>
      </div>
    );
  } else {
    window.location.pathname = "/";
  }
};

export default DashboardNavbar;
