// This function makes sure that an array of objects has no duplicates
export const getUniqueListBy = (arr, key) => {
  let newSet = new Set();
  return arr.filter(obj => {
    if (!newSet.has(obj[key])) {
      newSet.add(obj[key]);
      return true;
    }
    return false;
  });
}