import './guide.css';
import guide from '../../../shared/guide';

export default function Guide() {
    return <div dir="rtl" className="guide-container">
        <h1>دليل المستخدم</h1>
        {guide.map((item, index) =>
            <div key={index} className="video-container">
                <h3>{item.title}</h3>
                <iframe class="guide-video" width="560" height="315" src={item.link} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
        )}
    </div>;
}
