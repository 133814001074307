import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function useAuthChecker(userData) {
    const route = useNavigate();

    useEffect(() => {
        if (!sessionStorage.getItem("userId")) {
            route('/sign-in');
        }

        if ("isAdmin" in userData && !userData.isAdmin) {
            console.log(userData);
            route('/');
        }
    }, [userData]);
}
