import './quickstats.css';

const QuickStats = ({ ordersPerMonth, totalDeliveredOrders, totalSellings }) => {
    return (
        <div className="quick-stats">
            <h4>إحصائيات سريعة</h4>
            <div className="stat-boxes" dir="rtl">
                <div className="orders-per-month stat-box">
                    الطلبات خلال الشهر
                    <p>{ordersPerMonth || 0}</p>
                </div>
                <div className="total-delivered-orders stat-box">
                    إجمالي الطلبات المسملة
                    <p>{totalDeliveredOrders || 0}</p>
                </div>
                <div className="total-sellings stat-box">
                    إجمالي المبيعات
                    <p>{totalSellings || 0}</p>
                </div>
            </div>
        </div>
    )
}

export default QuickStats
