import './shippingdetails.css';


const ShippingDetails = ({ order }) => {
  const orderData = JSON.parse(order);
  return (
    <div className="shipping-details" dir='rtl'>
      <h2>تفاصيل الشحن</h2>
      <p className='flex-container'><span>الاسم</span> <span>{orderData.client.name}</span></p>
      <p className='flex-container'><span>رقم الهاتف</span> <span>{orderData.client.client_phone_number}</span></p>
      <p className='flex-container'><span>المحافظة</span> <span>{orderData.client.city}</span></p>
      <p className='flex-container'><span>العنوان</span> <span>{orderData.client.detailed_address}</span></p>
    </div>
  )
}

export default ShippingDetails