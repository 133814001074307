import { useParams } from 'react-router-dom';
import './editproduct.css';
import LoadingPatternOne from '../../../dashboard/loadingpatterns/LoadingPatternOne';
import Swal from 'sweetalert2';
import { api } from '../../../../app/store';
import { categories, categoriesInArabic } from "../../../../shared/categories";

const EditProduct = () => {

  const { productId } = useParams();
  const { data: product, isLoading } = api.useGetProductByIdQuery(productId);
  const [editProductApi] = api.useEditProductMutation({
    fixedCacheKey: "products"
  })

  let formData = {};

    const editProduct = e => {
        e.preventDefault();

        let pictures = [];
        document.getElementById("productPics").value.split(" ").map(imgLink => {
            pictures.push({pic_url: imgLink});
        });

        formData.name = document.getElementById("productName").value;
        formData.price = +document.getElementById("productPrice").value;
        formData.SKU = document.getElementById("productSKU").value;
        formData.suggested_price = +document.getElementById("productSuggestedPrice").value;
        formData.quality = document.getElementById("productQuality").value;
        formData.country_of_origin = document.getElementById("productCountryOfOrigin").value;
        formData.description = document.getElementById("productDescription").value;
        formData.pictures = pictures;
        formData.category = document.getElementById("productCategory").value;
        formData.video_url = document.getElementById("productVideo").value;
        formData.color = document.getElementById("productColor").value;
        formData.availability = document.getElementById("productAvailability").value;

    editProductApi({productId, ...formData})
      .unwrap()
      .then(result => {
        if(result !== "Edited") throw Error("مشكلة!");
        Swal.fire({
          icon: "success",
          title: "تم تعديل المنتج بنجاح"
        })
      })
      .catch(err => {
        Swal.fire(
          `${err}`,
          'حدث خطأ أثناء تعديل المنتج',
          'error'
        );
      });
  }

  if(isLoading) {
    return <LoadingPatternOne />
  }

  return (product &&
    <div className="background-image">
        <div className='container edit-product'>
          <h1>تعديل منتج</h1>
          <div className="form-container">
            <form onSubmit={editProduct}>
              <div className="mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">اسم المنتج</label>
                <input defaultValue={product.name} type="text" className="form-control" id="productName" placeholder="ماسك وجه" />
              </div>
              <div className="mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">ثمن البيع</label>
                <input defaultValue={product.price} type="text" className="form-control" id="productPrice" placeholder="4.5" />
              </div>
              <div className="mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">SKU</label>
                <input defaultValue={product.SKU} type="text" className="form-control" id="productSKU" placeholder="pc-c-80" />
              </div>
              <div className="mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">السعر المقترح</label>
                <input defaultValue={product.suggested_price} type="text" className="form-control" id="productSuggestedPrice" placeholder="10" />
              </div>
              <div className="mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">الجودة</label>
                <input defaultValue={product.quality} type="text" className="form-control" id="productQuality" placeholder="ممتاز, جيد, جيد جدا" />
              </div>
              <div className="mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">بلد المنشأ</label>
                <input defaultValue={product.country_of_origin} type="text" className="form-control" id="productCountryOfOrigin" placeholder="... ,الصين, كوريا" />
              </div>
              <div className="mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">حالة توفر المنتج</label>
                <input defaultValue={product.availability} type="text" className="form-control" id="productAvailability" placeholder="متوفر" />
              </div>
              <div className="mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">اللون</label>
                <input defaultValue={product.color} type="text" className="form-control" id="productColor" placeholder="لون المنتج" />
              </div>
              <div className="mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">التصنيف</label>
                <select dir="rtl" defaultValue={product.category ?? "other"} id="productCategory" className="form-select">
                    {categories.map(catrgory =>
                        <option key={catrgory} value={catrgory}>{categoriesInArabic(catrgory)}</option>
                    )}
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">صور المنتج</label>
                <input defaultValue={product.pictures.map(pic => pic.pic_url).join(" ")} type="text" className="form-control" id="productPics" placeholder="روابط صور المنتج مفصول بينها بمسافة" />
              </div>
              <div className="mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">رابط مقطع الفيديو</label>
                <input defaultValue={product.video_url} type="text" className="form-control" id="productVideo" placeholder="رابط الفيديو الخاص بالمنتج" />
              </div>
              <div className="mb-3">
                <label htmlFor="exampleFormControlTextarea1" className="form-label">وصف المنتج</label>
                <textarea defaultValue={product.description} className="form-control" id="productDescription" rows="3"></textarea>
              </div>
              <div className="button-container">
                <button type="submit" className="btn btn-danger">تعديل المنتج</button>
              </div>
            </form>
          </div>
        </div>
    </div>
  )
}

export default EditProduct
