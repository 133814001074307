import { useParams } from 'react-router-dom';
import LoadingPatternOne from '../../../dashboard/loadingpatterns/LoadingPatternOne';
import './viewuser.css';
import { api } from '../../../../app/store';
import { useGetLocalUserData } from '../../../../functions/useGetLocalUserData';
import useAuthChecker from '../../useAuthChecker';

const ViewUser = () => {

    const { userData: localUser } = useGetLocalUserData();
    useAuthChecker(localUser);

    const { id } = useParams();

    const { data: userData, isLoading } = api.useGetUserQuery(id);

    if(isLoading) return <LoadingPatternOne />

  return (userData &&
    <div className="admin-view-user-page">
      <div className="container">
        <h1>View User</h1>
        <div className="card-container">
          <div className="card" style={{width: '18rem'}}>
            <img src={userData.profile_picture || "https://st3.depositphotos.com/1767687/16607/v/450/depositphotos_166074422-stock-illustration-default-avatar-profile-icon-grey.jpg"} className="card-img-top" alt="..." />
            <div className="card-body">
              <h5 className="card-title">{userData.username}</h5>
            </div>
            <ul className="list-group list-group-flush">
              <li className="list-group-item">Email: {userData.email}</li>
              <li className="list-group-item">Phone Number: {userData.phone_number}</li>
              <li className="list-group-item">Company: {userData.company || "Unkown"}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ViewUser
