import { useContext, useEffect, useRef, useState } from "react";
import { ProductsContext } from "../ShoppingCart";
import { useRemoveProductFromCart } from "../../../../functions/useRemoveProductFromCart";

const CartItem = ({
    productItem,
    quantity,
    selling_price,
    addToTotalProfit,
    addToTotalCost,
    removeFromTotalProfit,
    removeFromTotalCost
}) => {
    const [itemQuantity, setItemQuantity] = useState(1);
    const [sellingPrice, setSellingPrice] = useState(0);
    const [totalForItem, setTotalForItem] = useState(0);
    const [itemCost, setItemCost] = useState(0);
    const subtractedFromTotalProfit = useRef(0);
    const subtractedFromTotalCost = useRef(0);

    const productsContext = useContext(ProductsContext);

    const removeProductFromCart = useRemoveProductFromCart(productItem._id);

    useEffect(() => {
        setItemQuantity(quantity);
        setSellingPrice(selling_price);
        setTotalForItem(quantity * selling_price);
        setItemCost(quantity * productItem.price);
    }, []);

    useEffect(() => {
        addToTotalProfit(totalForItem - subtractedFromTotalProfit.current);
    }, [totalForItem, subtractedFromTotalProfit]);

    useEffect(() => {
        addToTotalCost(itemCost - subtractedFromTotalCost.current);
    }, [itemCost, subtractedFromTotalCost]);

    const changeSellingPrice = e => {
        subtractedFromTotalProfit.current = itemQuantity * sellingPrice;
        setSellingPrice(e.target.value);
        setTotalForItem(itemQuantity * e.target.value);

        productsContext.current.map((single, index) => {
            if (single.productId === productItem._id) {
                productsContext.current.splice(index, 1);
                productsContext.current.push({ ...single, sellingPrice: e.target.valueAsNumber });
            }
        });
    };

    const changeItemQuantity = e => {
        subtractedFromTotalProfit.current = itemQuantity * sellingPrice;
        subtractedFromTotalCost.current = itemQuantity * productItem.price;

        setItemQuantity(+e.target.value > 0 ? +e.target.value : 1);

        setTotalForItem(e.target.value * sellingPrice);

        // Handle the change in total cost
        setItemCost(e.target.value * productItem.price);

        // change the quantity for this product in the over-all array
        productsContext.current.map((single, index) => {
            if (single.productId === productItem._id) {
                productsContext.current.splice(index, 1);
                productsContext.current.push({ ...single, quantity: e.target.valueAsNumber });
            }
        });
    };

    const deleteProductFromCart = () => {
        removeFromTotalProfit(itemQuantity * sellingPrice);
        removeFromTotalCost(itemQuantity * itemCost);
        removeProductFromCart();
    };

    /*<tr>
        <td><img src={productItem.pictures[0].pic_url} alt="صورة" /></td>
        <td>{productItem.name}</td>
        <td>{productItem.price} د.أ</td>
        <td><input
            type="number"
            id="sellingPrice"
            className="seelling-price"
            defaultValue={selling_price}
            onChange={changeSellingPrice}
            min="1"
        /></td>
        <td><input
            type="number"
            id="quantity"
            className="quantity"
            defaultValue={quantity}
            onChange={changeItemQuantity}
            min="1"
        /></td>
        <td><button className="btn btn-danger" onClick={deleteProductFromCart}>إزالة</button>
        </td>
    </tr>*/
    return (productItem &&
        <tr className="item">
            <td><img src={productItem.pictures[0].pic_url} alt="صورة" /></td>
            <td>{productItem.name}</td>
            <td>{productItem.price} د.أ</td>
            <td><input
                type="number"
                id="sellingPrice"
                className="seelling-price"
                defaultValue={selling_price}
                onChange={changeSellingPrice}
                min="1"
            /></td>
            <td><input
                type="number"
                id="quantity"
                className="quantity"
                defaultValue={quantity}
                onChange={changeItemQuantity}
                min="1"
            /></td>
            <td><button className="btn btn-danger" onClick={deleteProductFromCart}>إزالة</button>
            </td>
        </tr>

    )
}

export default CartItem
