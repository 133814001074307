import "./orderproducts.css";

export function OrderProducts({ products }) {
  products = JSON.parse(products);

  return (
    <div className="admin-order-products">
      <table class="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">picture</th>
            <th scope="col">Name</th>
            <th scope="col">Cost</th>
            <th scope="col">Selling price</th>
            <th scope="col">Quantity</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product, index) => {
            return <tr key={product._id}>
              <th scope="row">{index+1}</th>
              <td><img src={product.pictures[0].pic_url} alt="pic" width="50" height="50"/></td>
              <td>{product.name}</td>
              <td>{product.price}</td>
              <td>{product.sellingPrice}</td>
              <td>{product.quantity}</td>
            </tr>
          })}
        </tbody>
      </table>
    </div>
  );
}
