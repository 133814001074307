import { createContext, useRef, useState } from 'react';
import { getUniqueListBy } from '../../../functions/getUniqueListBy';
import DashboardNavbar from '../dashboardNavbar/DashboardNavbar';
import LoadingPatternOne from '../loadingpatterns/LoadingPatternOne';
import Sidebar from '../sidebar/Sidebar';
import CartItem from './cartitem/CartItem';
import OrderForm from './orderform/OrderForm';
import './shoppingcart.css';
import { useGetLocalUserData } from '../../../functions/useGetLocalUserData';
import { api } from '../../../app/store';

export const ProductsContext = createContext(null);

const ShoppingCart = () => {

    const [totalForAll, setTotalForAll] = useState(0);
    const [totalCost, setTotalCost] = useState(0);
    const { userData, isLoading: isLoadingUser } = useGetLocalUserData();
    const { data: products, isLoading: isLoadingProducts } = api.useGetProductsQuery();

    const productsData = useRef([]);

    const addToTotalProfit = addition => {
        setTotalForAll(old => old += addition);
    };

    const removeFromTotalProfit = subtraction => {
        setTotalForAll(old => old -= subtraction);
    };

    const addToTotalCost = addition => {
        setTotalCost(oldCost => oldCost += addition);
    };

    const removeFromTotalCost = subtraction => {
        setTotalCost(oldCost => oldCost -= subtraction);
    };

    return (
        <div className="shopping-cart-page">
            {(isLoadingProducts || isLoadingUser) && <LoadingPatternOne />}
            {(userData && products) &&
                <ProductsContext.Provider value={productsData}>
                    <div className="row-dashoard row">
                        <h1 align="right" style={{ paddingBottom: 20 }}>سلة المشتريات</h1>
                        <table class="table table-light" dir="rtl">
                            <thead>
                                <tr>
                                    <th scope="col">صورة</th>
                                    <th scope="col">الاسم</th>
                                    <th scope="col">التكلفة</th>
                                    <th scope="col">ثمن البيع</th>
                                    <th scope="col">الكمية</th>
                                    <th scope="col">الإجمالي</th>
                                </tr>
                            </thead>
                            <tbody>
                                {userData.shopping_cart && userData.shopping_cart.map(({ product_id, quantity, selling_price }) => {
                                    const productItem = products.filter(product => product._id === product_id)[0];
                                    productsData.current.push({
                                        productId: product_id,
                                        quantity: quantity,
                                        sellingPrice: selling_price
                                    });
                                    if (productsData.current.length > 0) {
                                        productsData.current = getUniqueListBy(productsData.current, 'productId');
                                    }
                                    return <CartItem
                                        key={product_id}
                                        addToTotalProfit={addToTotalProfit}
                                        addToTotalCost={addToTotalCost}
                                        removeFromTotalProfit={removeFromTotalProfit}
                                        removeFromTotalCost={removeFromTotalCost}
                                        productItem={productItem}
                                        quantity={quantity}
                                        selling_price={selling_price}
                                    />
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className="row-dashoard row">
                        <OrderForm
                            totalPrice={totalForAll}
                            totalCost={totalCost}
                            setTotalCost={setTotalCost}
                            setTotalPrice={setTotalForAll}
                        />
                    </div>
                </ProductsContext.Provider>
            }
        </div>
    )
}

export default ShoppingCart
