import { api } from "../app/store"

export function useGetLocalUserData() {
  const { data = {}, isLoading, isError, error } = api.useGetUserQuery(sessionStorage.getItem("userId"));

  return {
    userData: data,
    isLoading,
    isError,
    error
  }
}
