import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { api } from '../../../app/store';
import { useGetLocalUserData } from '../../../functions/useGetLocalUserData';
import { useAddProductToFav } from '../../../functions/useAddProductToFav';
import { useRemoveProductFromFav } from '../../../functions/useRemoveProductFromFav';
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import LoadingPatternOne from '../loadingpatterns/LoadingPatternOne';
import ProductCarousel from './carousel/Carousel';
import './productdetails.css';
import { useAddProductToCart } from '../../../functions/useAddProductToCart';
import { categoriesInArabic } from "../../../shared/categories";

const ProductDetails = () => {
    const { productId } = useParams();

    const { data: productData, isLoading } = api.useGetProductByIdQuery(productId);
    const { userData } = useGetLocalUserData();

    const addToCart = useAddProductToCart(productId, isLoading ? "" : productData.suggested_price)
    const addToFav = useAddProductToFav(productId);
    const removeFromFav = useRemoveProductFromFav(productId);

    const [isProductInFav, setIsProductInFav] = useState(false);
    useEffect(() => {
        if (userData.favorite_products) {
            userData.favorite_products.map(product => {
                if (product.product_id === productId.toString()) {
                    setIsProductInFav(true);
                }
            });
        }
    }, [userData, productId]);

    // function to downoload photos from the links
    const downloadPhotos = () => {
        const links = productData.pictures.map(pic => pic.pic_url).reverse();

        console.log(links);
        links.forEach(async (link, index) => {
            const response = await fetch(link);
            const blob = await response.blob();
            const filename = `photo${index + 1}.jpg`;

            // Create a temporary anchor element to trigger the download
            const anchor = document.createElement('a');
            anchor.href = window.URL.createObjectURL(blob);
            anchor.download = filename;
            anchor.style.display = 'none';

            document.body.appendChild(anchor);
            anchor.click();

            // Clean up
            document.body.removeChild(anchor);
            window.URL.revokeObjectURL(anchor.href);
        });
    }

    if (isLoading) return <LoadingPatternOne />;
    return (productData &&
        <div className="background-image">
            <div className="product-data">
                <div className="container">
                    <Link to="/dashboard/catalog/"><h2>العودة إلى كاتالوج المنتجات</h2></Link>
                    <h1>{productData.name}</h1>

                    <ProductCarousel productData={productData} />
                    <div className="d-flex justify-content-center py-2">
                        <button className="btn btn-success p-3 mb-3" onClick={downloadPhotos}>تحميل الصور</button>
                    </div>

                    {productData.video_url && (
                        <div className="ratio ratio-16x9">
                            <iframe width="420" height="315"
                                src={productData.video_url}>
                            </iframe>
                        </div>
                    )}

                    <div className="product-details">
                        <div className="left">
                            <p><span className="info-key">الوفرة</span> : {productData.availability}</p>
                            <p>{productData.SKU} : <span className="info-key">SKU</span></p>
                            <p><span className="info-key">الجودة</span> : {productData.quality}</p>
                            {productData.color && <p><span className="info-key">اللون</span> : {productData.color}</p>}
                        </div>
                        <div className="right">
                            <p><span className="info-key">الثمن</span> : {productData.price} د.أ</p>
                            <p><span className="info-key">سعر البيع المقترح</span> : {productData.suggested_price} د.أ</p>
                            <p><span className="info-key">بلد المنشأ</span> : {productData.country_of_origin}</p>
                            <p><span className="info-key">التصنيف</span> : {categoriesInArabic(productData.category) ?? "أخرى"}</p>
                        </div>
                    </div>
                    <div className="description-box">
                        <p>{productData.description}</p>
                    </div>
                    <div className="buttons">
                        <button onClick={() => {
                            addToCart()
                        }}><ShoppingCart /> أضف إلى السلة</button>
                        {isProductInFav
                            ? <button onClick={() => {
                                setIsProductInFav(false);
                                removeFromFav();
                            }}>إزالة من منتجاتي</button>
                            : <button onClick={() => {
                                setIsProductInFav(true);
                                addToFav();
                            }}>أضف إلى منتجاتي</button>
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductDetails
