import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import Header from "./Header";

export default function Terms() {
    return (
        <>
            <Navbar />
            <section className="terms" dir="rtl">
                <h1>سياسة الخصوصية</h1>
                <p>يرجى تخصيص جزء من وقتك لقراءة السياسة التالية لفهم كيف نستخدم المعلومات الشخصية التي نطلب منك تقديمها. إذا كنت تستخدم أي من خدمات بريميوم ستور ، فإنك توافق على هذه الشروط.</p>
                <h2>التزامنا بالخصوصية</h2>
                <p>خصوصيتك مهمة بالنسبة لنا. ولحماية أفضل لخصوصيتك نقدم هذا التنويه لشرح كيفية استخدامنا للمعلومات على الإنترنت والخيارات التي يمكنك اتخاذها حول طريقة جمع المعلومات الخاصة بك واستخدامها. لنجعل الوصول سهل لهذا التنويه وضعنا رابطه في القسم السفلي لكل صفحة من صفحات هذا الموقع.
                    عندما تسجل اشتراكك في بريميوم ستور  نقوم بسؤالك عن معلومات شخصية، نستخدم هذه المعلومات لإجراء طلباتك، وإجراء عمليات التحقق من الحساب، والتواصل معك بشأن وضع طلباتك.
                    أيضاً نستخدم بريدك الإلكتروني لنرسل لك نشرات العروض التي قمت بالاشتراك بها أثناء التسجيل، تحديثات عن العروض الخاصة لبريميوم ستور، الإعلانات الترويجية، واستطلاعات الرأي. أحياناً، نرسل لعملائنا العروض الخاصة والمعلومات الترويجية عبر البريد باستخدام عنوان المراسلة الذي قدمتموه. إذا كنت لا ترغب في استلام المزيد من الدعايات والخدمات الإعلانية التي قمت بالاشتراك بها مسبقاً، تستطيع بسهولة إلغاء اشتراكك بواسطة الضغط على روابط إلغاء الاشتراك الموجودة في رسائل البريد الإلكتروني.
                    المعلومات اللازمة لنا
                    هذا التنويه يحوي جميع المعلومات المطلوبة على موقع بريميوم ستور عند طلب عضوية، منتجات، أو طلبات أخرى. نوع المعلومات الشخصية المطلوبة في تلك الصفحات تتمثل في الآتي:</p>
                <ul>
                    <li> الاسم.</li>
                    <li> عنوان البريد.</li>
                    <li> البريد الإلكتروني.</li>
                    <li> رقم الهاتف.</li>
                    { /* <li> بيانات بطاقة الائتمان.</li> */}
                </ul>
                <p>في بعض الصفحات الأخرى من الممكن أن تقدم بيانات تخص أشخاص آخرين، مثال: إذا قمت بطلب منتج وتريد أن ترسله بشكل مباشر للعميل، فإنك ستحتاج أن تقدم عنوانه. في هذه الحالة سيكون نوع المعلومات المطلوبة كالآتي:</p>
                <ul>
                    <li> الاسم.</li>
                    <li> عنوان البريد الالكتروني.</li>
                    <li> عنوان الشحن</li>
                    <li> رقم الهاتف.</li>
                </ul>
                <h3>كيف يمكنك تصحيح بياناتك؟</h3>
                <p>
                    يمكنك الوصول إلى معلوماتك الشخصية التي تم جمعها من قبل بريميوم ستور عن طريق تسجيل الدخول إلى حسابك ثم الضغط على “تعديل الملف الشخصي”. كما يمكنك تعديل الأخطاء في معلومات التعريف الشخصية خاصتك عن طريق إرسال طلب لنا يوضح مصداقية الخطأ. ولحماية خصوصيتك سنقوم باتخاذ خطوات منطقية لتعريف هويتك قبل منحك وصولاً كاملاً لإجراء تعديلات.</p>
                <h2>سياسة البيانات</h2>
                <p>
                    بريميوم ستور لن تقوم بالإفصاح عن معلوماتك الشخصية المقدمة لنا أثناء التسجيل مع أي جهة كانت.
                    الاستخدام الأساسي لمعلوماتك الشخصية، بخلاف ما يتعلق بالشراء مباشرة، هو تزويدك بمعلومات ذات صلة بعمليات الشراء.
                    نحن أيضاً نستخدم معلومات التسجيل لنعلمك بجديد المزايا والعروض الأخرى ذات الاهتمام لكي نلبي احتياجات ومتطلبات العملاء.
                    ملفات تعريف الارتباط (كوكيز)
                    • خلافاً لما هو شائع، ملف تعريف الارتباط لا يأخذ أي معلومات خاصة أو شخصية من حاسوبك، بل على العكس فإنه ملف صغير جداً يقوم بتخزين معلوماتك التي تمد موقعنا بها وقت التسجيل ويحمّل على حاسوبك، ذلك الملف يمكّن خادم الإنترنت التابع لنا (سيرفر) من التعرف عليك كل مرة تعيد الزيارة لموقعنا. تستطيع إزالة كل ملفات الكوكيز الخاصة بك بسهولة بإتباع تعليمات المساعدة الخاصة بمتصفحك.
                    ملفات التسجيل (اللوج)
                    مثل معظم خوادم الإنترنت نقوم باستخدام ملفات التسجيل (لوج). وتقوم تلك الملفات بتخزين عناوين بروتوكول الإنترنت (إي بي)، نوع المتصفح، مزود خدمة الإنترنت (أي. إس. بي)، وصفحات الإحالة/ الخروج، ختم التاريخ/ الوقت، عدد النقرات لتحليل التوجهات، جمع معلومات ديموغرافية معينة، وعنوان الـ (آي بي) الخاص بك … إلخ. وكل هذا غير مرتبط بمعلومات التعريف الشخصية.</p>
                <h3>التواصل مع العملاء</h3>
                <p>اتصالات بريميوم ستور بالعملاء</p>
                <ul>
                    <li>تكون في حدود التعريف بالعروض الخاصة والتحديثات.</li>
                    <li>نرسل لجميع الأعضاء رسالة ترحيب لتفعيل اسم المستخدم وكلمة المرور.</li>
                    <li>
                        سيحصل الأعضاء أحيانا على معلومات عن المنتجات والخدمات والصفقات الخاصة. واحتراما لخصوصية مستخدمينا نقدم خدمة عدم تلقي مثل هذا النوع من الاتصالات.
                    </li>
                    <li>كما نقوم بإرسال عروض خاصة وتحديثات متعلقة بخدماتنا لجميع الأعضاء. إذا كنت من أعضائنا ستتسلم مثل هذه العروض والتحديثات إلا إذا قمت أنت بإلغاء اشتراكك في تلك الخدمة.</li>
                    <li>نشرات العروض</li>
                    <li>إذا رغب المستخدم الاشتراك في خدمة نشرات العروض نقوم بسؤاله عن معلومات الارتباط كالاسم والبريد الإلكتروني، واحتراما لخصوصية مستخدمينا، نوفر طريقة لتعطيل هذه الاتصالات.</li>
                    <li>إعلانات الصيانة</li>
                    <li>في حالات نادرة جداً إذا كانت خدماتنا معلقة، قيد الصيانة، أو معطلة مؤقتا سنقوم بإرسال بريد إلكتروني لأعضائنا نوضح ذلك. بوجه عام، لا يجوز للمستخدمين تعطيل هذه الاتصالات، على الرغم من أنه يمكنهم إلغاء تنشيط حسابهم. ومع ذلك، هذه الرسائل ليست ترويجية بطبيعتها.</li>
                    <li> خدمة العملاء</li>
                    <li>نقوم بالتواصل مع المستخدمين بشكل منتظم لنزودهم بالخدمات المطلوبة فيما يتعلق بحساباتهم. ونقوم بالرد عن طريق البريد الإلكتروني أو الهاتف فيما يتوافق مع رغباتهم.</li>
                    <li>اتصالات أخرى:
                        <ul>
                            <li>أثناء عملية التسجيل، نقوم بسؤالك عن معلومات الهاتف لأنه من الممكن الاتصال بك لأغراض إدارة الحساب. سنتصل بك أيضاً في الأسابيع الأولى من فترتك التجريبية للخدمة لنمدك بإجابات على أي أسئلة واستفسارات قد تواجهك.</li>
                            <li>قد نقوم بالاتصال هاتفياً لعروض ترويجية متاحة فقط عن طريق الهاتف. وقد نتواصل معك هاتفياً أيضاً لأغراض تتعلق بتحسين الخدمة بسؤالك بعض الأسئلة أو عمل استطلاع للرأي، أو عرض بعض الخدمات الأخرى التي قد تفييدك ربحياً.</li>
                        </ul>
                    </li>
                </ul>
                <h2>مشاركة البيانات</h2>
                <p>بريميوم ستور لن تقوم بالإفصاح عن معلوماتك الشخصية المقدمة لنا أثناء التسجيل إلا بعلمك وموافقتك كما هو موضح في سياسة الخصوصية هذه، أو حسبما يقتضيه القانون، أو لحماية حقوق ملكية بريميوم ستور.</p>
                <p>المعلومات التي تقدمها أثناء عمليات الشراء من موقعنا ( إسمك، عنوانك، بريدك الإلكتروني) من الممكن أن يتم مشاركتها مع الشركات التي لنا معها تعاملات تجارية تشمل الشركات التي من خلالها وصلت أنت لبريميوم ستور. على سبيل المثال، إذا قمت بشراء عرض خاص من موقع آخر يعمل كموزع لمنتجاتنا، في هذه الحالة ستعتبر عميلاً لهم أيضاً.</p>
                <p>لن نقوم بمشاركة كلمة مرورك أو معلومات الدفع خاصتك مع أي طرف آخر، باستثناء ما قد يقتضيه القانون.</p>
                <p>قد نقوم بمشاركة بعض المعلومات الخاصة بك (كالاسم، البريد الإلكتروني، رقم الهاتف، والعنوان البريدي) مع شركاء التسويق أو أي شركات أخرى بيننا وبينهم علاقات تجارية، لذلك قد يتواصلون معك بخصوص معلومات تخص خدمات قد تفيدك.</p>
                <p>
                    كما نحتفظ بالحق في الكشف عن إحصاءات المستخدمين المجمعة، مثل (45٪ من مستخدمينا من الإناث) من أجل وصف خدماتنا للشركاء المحتملين والمعلنين والأطراف الثالثة الأخرى.
                </p>
                <p>بموجب حماية اتفاقيات السرية، من الممكن أن تربط بريميوم ستور بيانات المستخدمين المتشابهة (كالاسم، العنوان، رقم الهاتف) ببيانات طرف ثالث لتجنب التكرار ومنع الأخطاء.</p>
                <h3>التزامنا بحماية البيانات</h3>
                <p>تتخذ بريميوم ستور كل الاحتياطات اللازمة لحماية معلومات مستخدميها. عندما يقوم المستخدمون بإرسال معلومات حساسة عبر الموقع الإلكتروني، فإن معلوماتهم محمية سواء كانوا متصلين أو لا.</p>
                <p>عندما يسأل نموذج التسجيل / الطلب المستخدمين إدخال معلومات حساسة (مثل رقم بطاقة الائتمان)، يتم تشفير هذه المعلومات ويتم حمايتها باستخدام أفضل برامج التشفير في هذه الصناعة – (إس. إس. إل).</p>
                <p>
                    أثناء وجودك في صفحة آمنة، مثل نموذج طلبنا، يصبح رمز القفل الموجود أسفل متصفحات الويب مثل “نيت سكيب” وميكروسوفت إنترنيت إكسبلورر مقفلا، على العكس يكون مفتوح عندما يكون المستخدمون (يتصفحون) فقط.
                </p>
                <p>
                    عندما نستخدم نظام التشفير (إس. إس. إل) لحماية المعلومات الحساسة على الإنترنت نقوم بحماية جميع معلومات المستخدمين لدينا، وليس فقط المعلومات الحساسة المذكورة أعلاه. فقط الموظفين الذين يحتاجون إلى معلومات لأداء وظيفة محددة (على سبيل المثال، موظف الفواتير أو ممثل خدمة العملاء) يتم منحهم الوصول إلى بعض تلك المعلومات. وعلاوة على ذلك، فإن بيانات جميع موظفينا الأمنية محدثة لدينا فيما يخص الأمان والخصوصية. وأخيراً، فإن الخوادم التي تخزن معلومات التعريف الشخصية هي في بيئة آمنة، وراء قفص مقفل في منشأة مقفلة.
                </p>
                <p>تحتفظ بريميوم ستور بالحق في تعديل أو تغيير بنود سياسة الخصوصية في أي وقت. في حال تغيير أي مواد في سياسة الخصوصية هذه سنعلم مستخدمينا بتلك التغيرات عن طريق البريد الإلكتروني. إذا كنت لا توافق على أي من تلك التعديلات يمكنك تعديل إعدادات الإشعارات لتعطيل إشعارات معينة من بريميوم ستور أو شركائها في التسويق. إذا كان لدى المستخدمين أي أسئلة عن الحماية على موقعنا برجاء التواصل مع خدمة العملاء.</p>
                <h3>التزامنا بحماية خصوصية الأطفال</h3>
                <p>
                    لحماية خصوصية الصغار أهمية خاصة. لهذا السبب؛ نحن لا نأخذ أو نحفظ معلومات للأطفال أقل من 13 عام على موقعنا. ولا يوجد في موقعنا أي محتوى يجذب الأطفال أقل من 13 عام. وللاشتراك في خدمات بريميوم ستور يجب أن يكون العضو فوق سن ال 18 ولديه بطاقة ائتمان صالحة.
                </p>
                <h2>للتواصل مع بريميوم ستور</h2>
                <p>
                    في حال كان لديك أي أسئلة أو استفسارات أخرى برجاء التواصل مع فريق الدعم الفني على البريد الإلكتروني premiumstoreconnect@gmail.com
                    آخر تحديث في اكتوبر (شوال) 2023
                </p>
                <h1>الشروط والأحكام</h1>
                <p>
                    يدار موقع بريميوم ستور من شركة بريميوم ستور الكائنة في الأردن. تنطبق شروط الخدمة هذه على شركة بريميوم ستور فقط.
                </p>
                <ul>
                    <li>الإشعارات الواردة في هذه الوثيقة وشروط أخرى قابلة للتطبيق تحكم استخدامك لخدمة منصة بريميوم ستور.</li>
                    <li>استخدامك بالتحديد لمنصة بريميوم ستور مشمول بخدمات منصة بريميوم ستور قد يخضع لشروط إضافية محددة بأماكن أخرى في الموقع .</li>
                    <li>باستخدام، زيارة، أو تصفح موقع منصة بريميوم ستور فإنك تقبل وتوافق على الالتزام بشروط الاستخدام هذه.</li>
                    <li>إذا كنت لا توافق على شروط استخدام منصة بريميوم ستور، لا يتوجب عليك استخدام خدمات منصة بريميوم ستور.</li>
                    <li>شروط الاستخدام هذه هي عقد مستمر بينك وبين منصة بريميوم ستور ينطبق على استخدامك لخدمة منصة بريميوم ستور.</li>
                </ul>
                <h3>تغيير شروط الاستخدام</h3>
                <p>تحتفظ منصة بريميوم ستور بالحق من وقت لآخر – بإشعارك أو عدم إشعارك – أن تغيّر شروط الاستخدام هذه وفق تقديرها المطلق.</p>
                <p>
                    يمكن مراجعة أحدث نسخة من شروط الاستخدام هذه من خلال النقر على الروابط التي توجهك إلى الشروط على منصة بريميوم ستور. أحدث نسخة من شروط الاستخدام تحل محل جميع النسخ السابقة. استخدامك لمنصة بريميوم ستور أو مواصلة استخدامك لخدماتنا بعد إجراء التغيرات يعني أنك موافق على الالتزام بمثل هذه التغيرات.
                </p>
                <h3>سياسة العضوية والخصوصية</h3>
                <p>
                    أنت توافق على اتفاقية سياسة العضوية والخصوصية بمجرد اشتراكك لتصبح وكيلا لمنصة بريميوم ستور لبيع منتجاتها عبر المنافذ الخاصة عبر الانترنت او عبر التجارة التقليدية بحسب الالتزامات والشروط التي توضحها هذه الوثيقة وتستمر صلاحية هذه الوكالة حتى انتهاء العضوية. أي معلومات تم إرسالها لمنصة بريميوم ستور هي خاضعة لاتفاقية سياسة العضوية والخصوصية خاصتنا. رجاءً مراجعة هذه السياسة لفهم ممارساتنا.
                </p>
                <h3>العلاقة بين منصة بريميوم ستور وبينك</h3>
                <p>
                    تحتفظ بريميوم ستور بالحق من وقت لآخر – بإشعارك أو عدم إشعارك – أن تغيّر شروط الاستخدام هذه وفق تقديرها المطلق.
                </p>
                <p>
                    الأعضاء هم وكلاء متعهدون مستقلون في منصة بريميوم ستور ولا يعتبرون موظفين فيها. يحظر على الأعضاء في أي دولة قيد منصة بريميوم ستور في أي عقد يتعلق بعمل لهم دون إذن خطي
                    الزائر/العضو بإكماله لعملية تسجيل العضوية يؤكد بموجب القانون أنه بلغ السن القانوني في بلد إقامته.
                </p>
                <h3>الوصول إلى الحساب وحماية الهوية</h3>
                <p>
                    من أجل إمداد بوصول سهل لحسابك، سوف تضع منصة بريميوم ستور ملف تعريف الارتباط (ملف نصي صغير كوكيز) على أي جهاز كمبيوتر تتصل منه إلى موقع منصة بريميوم ستور. عند إعادة زيارة منصة بريميوم ستور، سوف يمكننا هذا الملف من التعرف عليك كمستخدم سابق أو كمالك للحساب ويوفر لك ذلك إمكانية الوصول المباشر إلى حسابك دون مطالبتك بإعادة كتابة أي كلمة مرور أو هوية مستخدم أخرى. أنت المسؤول عن تحديث ومصداقية ودقة المعلومات التي تمدنا بها والمتعلقة بحسابك.
                </p>
                <p>
                    أنت أيضا مسؤول عن حفظ سرية حسابك وكلمة مرورك وأيضاً عن حصر الوصول إلى حاسوبك. مع ذلك، في حال قررت مشاركة حسابك مع أشخاص آخرين فإنك بذلك تتحمل كامل المسؤولية لأفعالهم ومسؤوليتهم. مستخدمي الأجهزة العامة يجب عليهم تسجيل الخروج من حساباتهم عقب انتهاء زيارته لمنصة بريميوم ستور.
                </p>
                <p>
                    إذا وجدت أنك ضحية لسرقة الهوية وهذا يتضمن حساب على منصة بريميوم ستور، عليك إبلاغ خدمة العملاء بذلك. أيضاً يجب عليك إبلاغ مُصدر بطاقات الائتمان، بالإضافة إلى إبلاغ الجهات القانونية المختصة بذلك.
                </p>
                <p>
                    تحتفظ منصة بريميوم ستور بالحق في إلغاء أي حساب معلق في أي وقت مع أو دون إشعار للمشترك من أجل حماية نفسها وشركائها مما يعتقد أنه نشاط احتيالي.
                </p>
                <h3>اختبار الخدمة</h3>
                <p>
                    من وقت لآخر نقوم باختبار العديد من الجوانب لخدمتنا ونمتلك الحق بشملك في تلك الاختبارات دون إشعارك.
                </p>
                <h3>المرجعية القانونية</h3>
                <p>
                    تتفق أنت ومنصة بريميوم ستور على أنه يجب أن تكون قوانين المملكة الأردنية حصرياً هي الفيصل في أي نزاع، مطالبات، سياسات، أو أي إجراء قانوني بينك وبين منصة بريميوم ستور فيما يخص خدمات موقعها الإلكتروني، أو شروط الاستخدام هذه. أنت ومنصة بريميوم ستور صراحة وبموافقة لا رجعة فيها تتفقون أن هذه المحاكم خاصة بالمرجعية القانونية. ويدفع الطرف الخاسر جميع أتعاب المحاماة وتكاليف المحكمة.
                </p>
                <h3>قيود الاستخدام</h3>
                <p>
                    يجب أن تكون فوق سن ال 18 للاشتراك في خدمات منصة بريميوم ستور. في حين أنه بالنسبة للأشخاص تحت سن الـ 18 يمكنهم الاستفادة من الخدمة فقط بمشاركة أحد الوالدين أو الوصي القانوني. ما لم يُنص على خلاف ذلك. في تلك الحالة، نقوم بمنحك رخصة محدودة للوصول إلى منصة بريميوم ستور لا تمنحك حق التحميل المحتوى (غير التخزين المؤقت اللازم للاستخدام الشخصي) ولا تمنحك أيضاً الحق في تعديل، نسخ، توزيع، نقل، عرض، تنفيذ، إعادة إنتاج، تكرار، نشر، ترخيص، إنشاء أعمال مشتقة من محتوى، أو بيع أي معلومات يحتوي عليها أو مأخوذة من منصة بريميوم ستور بدون موافقتنا الخطية الصريحة. أي استخدام غير مصرح به لمنصة بريميوم ستور أو محتوياته سوف ينهي الترخيص المحدود الممنوح لك من قبلنا.
                </p>
                <h3>السرية</h3>
                <p>
                    يتفهم المشترك أن جميع المعلومات التي تقدمها شركة منصة بريميوم ستور سرية وملكية لشركة بريميوم ستور ويجب أن يستخدمها المورّد وفقا لشروط هذه الاتفاقية. يوافق المشترك على عدم تقديم أي معلومات سرية أو مسجلة من منصة بريميوم ستور إلى أي كيان تجاري قد ينافس منصة بريميوم ستور. أي انتهاك للسرية هو سبب الإنهاء الفوري للعضوية.
                </p>
                <h3>التعديلات</h3>
                <p>
                    تحتفظ منصة بريميوم ستور بالحق في إجراء تعديلات على هذه الاتفاقية في أي وقت. قد تكون التعديلات نتيجة للتغيرات في العوامل الاقتصادية أو القانونية أو غيرها من العوامل التي تؤثر على أعمال منصة بريميوم ستور.
                </p>
                <h3> الدفع</h3>
                <ul>
                    <li>استخدام خدمة منصة بريميوم ستور فإنك توافق صراحة على أنه يحق لمنصة بريميوم ستور أن نرسل لك فاتورة اشتراك شهري أو سنوي متكرر. أي ضرائب أو أي مصاريف أخرى يمكن أن تتحملها عند استخدامك لمنصة بريميوم ستور كما هو مذكور في شروط الاستخدام؛ تشير عبارة “الدفع” إلى خصم أو دفع قابل للتطبيق وفقاُ لطريقة الدفع خاصتك.</li>
                    <li>يتفهم العضو أنه يجب أن تصاحب جميع الطلبات ورسوم العضوية وسيلة دفع صالحة.</li>
                    <li>سيتم احتساب مصاريف اشتراكك منذ بداية اشتراكك وهكذا ستجدد شهرياً أو سنوياً إذا لم/ أو أن تقوم أنت بإلغاء العضوية.</li>
                    <li>انقر على رابط “تفاصيل الدفع” ضمن قسم “حسابي” في حساب منصة بريميوم ستور خاصتك لمعرفة تاريخ تجديد اشتراكك القادم.</li>
                    <li>كل الدفعات التي يتم خصمها غير قابلة للاسترجاع. من الممكن أن نقوم بتغيير الرسوم أو أن نضيف رسوم من وقت لآخر. ولكننا سنعلمك بإشعار مسبق بهذه التغيرات عن طريق البريد الإلكتروني أو في حسابك على منصة بريميوم ستور بمجرد دخولك. في حال أردت استخدام طريقة دفع أخرى أو كان هناك تغير في صلاحية بطاقتك الائتمانية، يمكنك تعديل معلومات وسيلة الدفع بواسطة الضغط على زر “حسابي” الموجود في أعلى صفحات موقع منصة بريميوم ستور.</li>
                </ul>
                <h3>الإتصالات الإلكترونية</h3>
                <p>
                    باستخدامك لخدمات منصة بريميوم ستور فإنك توافق على استقبال اتصالات هاتفية وبريد إلكتروني من منصة بريميوم ستور. هذه الاتصالات ستحوي إشعارات عن حسابك (كتحديثات طلبكم واستقبال رسائل البريد الإلكتروني) ومعلومات مرتبطة بالخدمة. هذه الاتصالات هي جزء من علاقتك بمنصة بريميوم ستور ويحق لك الحصول عليها كجزء من اشتراك منصة بريميوم ستور. إنك توافق على أن أي إشعار أو اتفاقية أو أية وسيلة اتصالات أخرى نرسلها إليك إلكترونياً سوف تفي بأي متطلبات قانونية تتعلق بالاتصالات، بما في ذلك الرسائل المكتوبة.
                </p>
                <h3>تسعير المنتجات</h3>
                <p>
                    منصة بريميوم ستور لها الحق في تعديل أسعار المنتجات في أي وقت. بسبب احتمالية تحريف المعلومات، قد نقوم بتصحيح الأخطاء المطبعية المتعلقة بالأسعار في أي وقت. يجب أيضاً على الأعضاء إدراك أن أسعار المنتجات يمكن أن تتقلب نتيجة تحديث المورّد لسعر الجملة الخاص بهم. منصة بريميوم ستور ليس لديها أي تحكم في تسعير المورّدين ولا على سياسات الشحن والتسليم وبناءً على ذلك فلا نستطيع ضمان بقاء أي منتج على أي سعر محدد لأي مدة زمنية.
                </p>
                <h3>استلام الطلبات</h3>
                <p>
                    تقوم بريميوم ستور باستلام الطلبات في أوقات الدوام الرسمية أيام العمل الرسمية فقط . يتم التأكد من توفر الكميات للطلبات وإذا كانت متوافرة يتم تجهيز الطلب وشحنه
                </p>
                <h3>الضرائب</h3>
                <p>
                    كعميل لـ “منصة بريميوم ستور” تتفهم أنك كيان مستقل بشكل مطلق عن منصة بريميوم ستور، فأنت المسؤول عن دفع ضرائب بلدك وفقاً للقوانين المعمول بها هناك. مسؤولية الضرائب هذه تشمل جميع ضرائب المبيعات المحددة من قبل الدولة والسلطات المحلية في بلدك.
                </p>
                <h3>الإلغاء</h3>
                <p>يمكنك إلغاء حسابك بعد التواصل مع خدمة العملاء</p>
                <h3>تصدير البيانات</h3>
                <p>
                    نظراً لطبيعة استيراد البيانات استرجاع الدفع غير مكفول لهذه الخدمة.
                </p>
                <h3>الضمان</h3>
                <p>
                    كل ضمانات المنتجات التي يتم شراؤها من منصة بريميوم ستور هي مسؤولية المصنعين ووكلائهم. وتتولى بريميوم ستور بالنيابة المتابعة معهم
                </p>
                <h3>المنتجات</h3>
                <p>
                    تسعى منصة بريميوم ستور بجد لتوفير معلومات دقيقة قدر الإمكان. مع ذلك، جميع أوصاف المنتج مقدمة بواسطة المصنع المقدم للمنتج وتقع عليه المسؤولية بذلك. تقوم بريميوم ستور بالتشييك على المنتجات حين طلبها لمطابقتها الفاتورة .
                </p>
                <h3>تصدير البيانات</h3>
                <p>
                    تعمل منصة بريميوم ستور بجد لتوفير أدق المعلومات قدر الإمكان. مع ذلك، جميع أوصاف المنتج مقدمة بواسطة المورّد، منصة بريميوم ستور مع كامل الاحترام لا تضمن بشكل صريح أو ضمني دقة المعلومات شاملة السعر ووصف المنتج. هذا وبالإضافة إلى ذلك ونظراً لحجم الطلبات الكبير وقيود المصدر؛ تصدير البيانات في بعض الأحيان وارد أن يتأخر أو يحتوي على بيانات أقدم من المعروض على الموقع .
                </p>
                <h3> إعادة البيع</h3>
                <p>
                    المنتجات المباعة تعتبر ملكية المشترك وبالتالي يحق له اعادة بيعها في حالة استرجعها من عميله حسب سياسة وشروط بيع العضو لعملائه
                    غير مسموح للأعضاء إعادة بيع عضويات في منصة بريميوم ستور أو أي بيانات بدون تصريح صريح خطي من منصة بريميوم ستور. أيضاً تحميل أي بيانات أو (تصوير شاشة) لمنصة بريميوم ستور أو أي مواقع تابعة ممنوع بدون الحصول على تصريح خطي صريح مسبق. الانتهاك سيؤدي إلى إلغاء حسابك وسيعتمد ذلك على خطورة الانتهاك، ونحن نمتلك الحق لتوجيه التهم الجنائية أو طلب التعويض المدني عن الأضرار والنفقات المفقودة.
                </p>
                <h3>الاتصال بمورّدين منصة بريميوم ستور</h3>
                <p>
                    لا توفر منصة بريميوم ستور بيانات الموردين لأنها الطرف المسؤول عن التجهيز والتوريد عبر مخازنها</p>
                <h3>مبيعات المنتجات وأماكن البيع</h3>
                <p>
                    المنتجات المعروضة في منصة بريميوم ستور خاضعة فقط لسياسات وشروط المملكة الأردنية الهاشمية وبالتالي العضو مسؤول مسؤولية كاملة في عرض المنتجات التي تتناسب وقوانين بلده ويحق له عرضها بالأسلوب والمكان الذي يناسبه
                </p>
                <h3>رسوم الشحن والتسليم</h3>
                <p>
                    نحن المسؤولون عن دفع كافة تكاليف الشحن والتسليم لجميع المنتجات المشتراة من بريميوم ستور. نحن أيضاً مسؤولون عن تحمل نفقات تلك الشحنات المرفوضة أو المعادة لأي سبب. نحن لسنا مسؤولين عن الأضرار العرضية أو التبعية أو الخسائر عن المنتجات المعيبة، أخطاء في شحن طلبك، أو أخطاء أخرى. الحد الأدنى من المسؤولية لدينا هو استبدال أي منتج تشتريه منا. أي شحنات زائدة الوزن أو الحجم قد تتطلب رسوم شحن إضافية من بريميوم ستور لن يتم شحنها حتى تقوم بتأكيد تحملك لتكلفة الشحن الإضافية. اعتمادا على المعلومات المقدمة من قبل المورّد، بريميوم ستور قد تنشر أوزان الشحن للبضائع. نشر أو عدم وجود أوزان الشحن لا يمكن أن يستخدم لتحديد تكلفة الشحن و/أو التزامك المالي. “تكلفة الشحن” موجودة لجميع المنتجات كل في صفحته وعربة الشحن يجب أن تستخدم فقط لتحديد تكلفة الشحن.
                </p>
                <h3>تحديد الطلب</h3>
                <p>
                    منصة بريميوم ستور ليست مسؤولة عن خسارة أو أي تلفيات ناجمة عن سوء إجراء عملية الطلب أو عن أي أخطاء كتابية تحدث أثناء تحديد الطلب. الأعضاء يتحملون كامل المسؤولية في تحديد دقة الطلب، تلك الدقة تشمل نوع السلعة، التسعير، الشحن والتسليم.
                </p>
                <h3>إجراء تعديلات على الطلب</h3>
                <p>
                    بشكل عام، بمجرد إتمام الطلب لا يمكن تغييره بأي حال من الأحوال. مع ذلك تحت ظروف معينة، يمكن أن تعدل أو تلغى بعض الطلبات في حال تواصل العضو مع قسم خدمة عملاء منصة بريميوم ستور بطلب تغيير باستخدام الإنترنت في أقرب وقت ممكن؛ في تلك الحالة قد يكون من الممكن أحيانا تعديل الطلب إذا لم يكن أعد بالفعل. منصة بريميوم ستور ليست مسؤولة عن أي خسارة أو تلفيات تنتج عن تغيير الطلب بعد أن تم تحديده مسبقاً.
                </p>
                <h3>ترجيع الطلب</h3>
                <p>
                    لا تسمح منصة بريميوم ستور باسترجاع الطلب للمنتج الذي تم شراؤه من قبلها إلا في حالة كون المنتج متضرر او مغاير لما تم طلبه من الموقع الالكتروني.
                </p>
                <h3> حقوق النشر</h3>
                <p>
                    تمتلك منصة بريميوم ستور وداعميها وشركاؤها المساهمين كل المحتوى المنشور على موقع منصة بريميوم ستور والمنشور لدى المشتركين كجزء من الخدمة شامل النصوص، الصور، الشعارات، التصاميم، المقاطع الصوتية والفيديو، التنزيلات الرقمية، تصنيفات البيانات والبرمجيات هي ملكية منصة بريميوم ستور. هذا المحتوى محمي بواسطة قوانين حماية الملكية الفكرية الأردنية والعالمية. هذا المحتوى لا يجب أن يعاد نشره أو استخدامه بدون الحصول على إذن خطي صريح من منصة بريميوم ستور أو داعميها. يحق لمنصة بريميوم ستور إنهاء عضويتك بموجب تقديرها المطلق إذا كان في تقديرها انتهاكك لهذه الفقرة. مثل هذه الانتهاكات تشمل النسخ أو الاستخدام غير المصرح به للمحتوى التابع لمنصة بريميوم ستور أو إعادة إنتاج أو تعديل صور منتج من الممكن أن تستخدم لأغراض الدعاية أو إعادة البيع.، منصة بريميوم ستور تسمح لعملائها إعادة بيع المنتجات من كتالوجاتها فقط.
                </p>
                <h3>العلامات التجارية</h3>
                <p>
                    بريميوم ستور هي علامة مسجلة لمنصة بريميوم ستور وبعض العلامات الأخرى هي علامات تجارية لمنصة بريميوم ستور . لا يجوز استخدام علامات منصة بريميوم ستور التجارية فيما يتعلق بأي منتج أو خدمة بأي طريقة من شأنها أن تسبب الارتباك بين العملاء، أو بأي طريقة من شأنها أن تمس حقوق منصة بريميوم ستور أو بأي شكل من الأشكال ينتقص من منصة بريميوم ستور. جميع العلامات التجارية الأخرى التي لا تمتلكها منصة بريميوم ستور والتي تظهر على منصة بريميوم ستور هي ملك لأصحابها؛ الذين قد ينتمون أو لا ينتمون إلى منصة بريميوم ستور.
                    أي صور لأشخاص يحتويها موقع منصة بريميوم ستور لا تعتبر مؤشر تأييد لأي منتج معين أو خدمة ما لم يذكر خلاف ذلك على وجه التحديد.
                </p>
                <h3>استخدام المحتوى المقدم لبريميوم ستور</h3>
                <p>
                    منصة بريميوم ستور لها الحرية في استخدام أي تعليقات، معلومات، أفكار، مفاهيم، نقد، أو أساليب تحتويها أي وسيلة اتصال من الممكن أن تُرسل إلى منصة بريميوم ستور تشمل الإجابات المقدمة في استطلاعات الرأي أو عن طريق المنشورات لمنصة بريميوم ستور بدون أي تعويضات مادية أو حتى إشعار أياً كان المحتوى ولأي غرض، ولكن ليس على سبيل تطوير أو تسويق منتجات، أو حتى تحسين منصة بريميوم ستور أو أي مواقع أخرى تابعة له. وعلاوة على ذلك، من خلال نشر أي معلومات على منصة بريميوم ستور أو تقديم اقتراحات أو في الرد على الاستبيانات؛ فإنك تمنحنا ترخيصاً غير حصري وخالي من حقوق الملكية لعرض هذه المعلومات أو استخدامها أو إعادة إنتاجها أو تعديلها.
                </p>
            </section>
            <Footer />
        </>
    );
}
