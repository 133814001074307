import PaidIcon from '@mui/icons-material/Paid';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import './home.css';

const Features = () => {
  return (
    <div className="features" id="featuresBox">
      <div className="container">
        <h1>المميزات</h1>
        <div className="feature">
          <span className="feature-text">منتجات بسعر الجملة</span>
          <PaidIcon className="features-icon" />
        </div>
        <div className="feature">
          <span className="feature-text">توصيل سريع وامن</span>
          <LocalShippingIcon className="features-icon" />
        </div>
        <div className="feature">
          <span className="feature-text">جودة ممتازة وفريدة</span>
          <ThumbUpIcon className="features-icon" />
        </div>
        <div className="feature">
          <span className="feature-text">تصوير احترافي للمنتجات</span>
          <CameraAltIcon className="features-icon" />
        </div>
      </div>
    </div>
  )
}

export default Features
