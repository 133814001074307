import { Link } from 'react-router-dom';
import './productdetails.css';
import { api } from '../../../app/store';

const ProductDetails = ({ productId, number, quantity, sellingPrice }) => {
  const { data: productData, isLoading } = api.useGetProductByIdQuery(productId);
  if(isLoading) {
    return (
      <tr>
        <td colSpan={5}>
          Loading...
        </td>
      </tr>
    )
  }
  return (productData &&
    <tr>
      <th scope="row">{number}</th>
      <td>
        <img src={productData.pictures[0].pic_url} width="50" height="50" alt="" />
      </td>
      <td>
        <Link to={`/dashboard/catalog/details/${productData._id}`}>
          {productData.name}
        </Link>
      </td>
      <td>{productData.price}</td>
      <td>{quantity}</td>
      <td>{sellingPrice}</td>
    </tr>
  )
}

export default ProductDetails
