import { api } from "../app/store";

export function useGetLocalUserNotifications() {
  const { data = [], isLoading, isError, error } = api.useGetNotificationsQuery(sessionStorage.getItem("userId"));
  return {
    notifications: data,
    isLoading,
    isError,
    error
  };
}