import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import changeOrderState from "../../../../functions/changeOrderState";
import "./orderitem.css";
import { api } from "../../../../app/store";

const determineColor = (state) => {
  switch (state) {
    case "معلقة":
      return "table-warning";
    case "قيد التسليم":
      return "table-primary";
    case "مسلمة":
      return "table-success";
    case "ملغية":
      return "table-danger";
    default:
      return "";
  }
};

const OrderItem = ({ id, index, state, date, quantity, total }) => {
  const [changeState] = api.useChangeOrderStateMutation({
    fixedCacheKey: "orders",
  });

  return (
    <tr className={determineColor(state)}>
      <th scope="row">{index}</th>
      <td>{new Date(date).toDateString()}</td>
      <td>{total} د.أ</td>
      <td>{quantity}</td>
      <td>{state}</td>
      <td>
        {state !== "ملغية" && state !== "مسلمة" && (
          <CloseIcon
            onClick={() => {
              changeState({
                orderId: id,
                body: { order_state: "ملغية" },
              })
                .unwrap()
                .then((res) => {
                  if (res !== "Changed order state") throw new Error("مشكلة");
                })
                .catch((err) => {
                  console.log(err);
                });
            }}
            style={{
              color: "red",
              cursor: "pointer",
              marginLeft: 10,
            }}
          />
        )}
        <Link to={`/admin/orders/${id}`}>
          <SearchIcon />
        </Link>
      </td>
    </tr>
  );
};

export default OrderItem;
