import Swal from 'sweetalert2';
import './createproduct.css';
import { api } from '../../../../app/store';
import { categories, categoriesInArabic } from "../../../../shared/categories"
import { useGetLocalUserData } from '../../../../functions/useGetLocalUserData';
import useAuthChecker from '../../useAuthChecker';

const CreateProduct = () => {

    const { userData } = useGetLocalUserData();
    useAuthChecker(userData);

  const [addProduct, result] = api.useCreateProductMutation({
    fixedCacheKey: "products",
  })

  let formData = {};

    const createProduct = e => {
        e.preventDefault();
        let pictures = [];
        document.getElementById("productPics").value.split(" ").map(imgLink => {
            pictures.push({pic_url: imgLink});
        });
        formData.name = document.getElementById("productName").value;
        formData.price = +document.getElementById("productPrice").value;
        formData.SKU = document.getElementById("productSKU").value;
        formData.suggested_price = +document.getElementById("productSuggestedPrice").value;
        formData.quality = document.getElementById("productQuality").value;
        formData.country_of_origin = document.getElementById("productCountryOfOrigin").value;
        formData.description = document.getElementById("productDescription").value;
        formData.pictures = pictures;
        formData.category = document.getElementById("productCategory").value;
        formData.video_url = document.getElementById("productVideo").value;
        formData.color = document.getElementById("productColor").value;
        formData.availability = document.getElementById("productAvailability").value;

        addProduct(formData)
            .unwrap()
            .then(result => {
                if(result !== "Product Created") throw Error("مشكلة!");
                Swal.fire(
                    'تمن العملية',
                    'تم إضافة المنتج بنجاح',
                    'success'
                );
            })
            .catch(err => {
                Swal.fire({
                    icon: "error",
                    html: "حدث خطأ أثناء إضافة المنتج!",
                    title: `${err}`
                });
            });

    }

  return (
    <div className="background-image">
        <div className='container create-product'>
          <h1>إنشاء منتج جديد</h1>
          <div className="form-container">
            <form onSubmit={createProduct}>
              <div className="mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">اسم المنتج</label>
                <input type="text" className="form-control" id="productName" placeholder="ماسك وجه" />
              </div>
              <div className="mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">ثمن البيع</label>
                <input type="text" className="form-control" id="productPrice" placeholder="4.5" />
              </div>
              <div className="mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">SKU</label>
                <input type="text" className="form-control" id="productSKU" placeholder="pc-c-80" />
              </div>
              <div className="mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">السعر المقترح</label>
                <input type="text" className="form-control" id="productSuggestedPrice" placeholder="10" />
              </div>
              <div className="mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">الجودة</label>
                <input type="text" className="form-control" id="productQuality" placeholder="ممتاز, جيد, جيد جدا" />
              </div>
              <div className="mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">بلد المنشأ</label>
                <input type="text" className="form-control" id="productCountryOfOrigin" placeholder="... ,الصين, كوريا" />
              </div>
              <div className="mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">حالة توفر المنتج</label>
                <input type="text" className="form-control" id="productAvailability" placeholder="متوفر" />
              </div>
              <div className="mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">اللون</label>
                <input type="text" className="form-control" id="productColor" placeholder="لون المنتج" />
              </div>
              <div className="mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">التصنيف</label>
                <select dir="rtl" id="productCategory" defaultValue="other" className="form-select">
                    {categories.map(catrgory =>
                        <option key={catrgory} value={catrgory}>{categoriesInArabic(catrgory)}</option>
                    )}
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">صور المنتج</label>
                <input type="text" className="form-control" id="productPics" placeholder="روابط صور المنتج مفصول بينها بمسافة" />
              </div>
              <div className="mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label">رابط مقطع الفيديو</label>
                <input type="text" className="form-control" id="productVideo" placeholder="رابط الفيديو الخاص بالمنتج" />
              </div>
              <div className="mb-3">
                <label htmlFor="exampleFormControlTextarea1" className="form-label">وصف المنتج</label>
                <textarea className="form-control" id="productDescription" rows="3"></textarea>
              </div>
              <div className="button-container">
                <button type="submit" className="btn btn-danger">إنشاء المنتج</button>
              </div>
            </form>
          </div>
        </div>
    </div>
  )
}

export default CreateProduct
