import { Link } from 'react-router-dom';
import '../../pages/home/home.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <h4>الشركة</h4>
                        <div className="comp-links">
                            <Link to="/terms">سياسة الخصوصية والشروط والأحكام</Link>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="other-links">
                            <div className="devider">
                                <Link to="/about">من نحن</Link>
                            </div>
                            <div className="devider">
                                <Link to="/contact-us">تواصل معنا</Link>
                                <Link to="/sign-in">تسجيل دخول</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <h4>Premium Store</h4>
                        <p>طريقك للتجارة</p>
                    </div>
                </div>
            </div>
            <div className="sub-footer">
                <p>جميع الحقوق محفوظة</p>
            </div>
        </footer>
    )
}

export default Footer
