// Layout for the dashboard
import { LayoutDashboardNavbar } from "./dashboardNavbar/DashboardNavbar";
import { LayoutSidebar } from "./sidebar/Sidebar";
import "./dashboard-layout.css";
import Catalog from "./catalog/Catalog";

export default function DashboardLayout({ children }) {
    return (
        <div className="d-flex w-100 layout-container">
            <div className="left-container">
                <LayoutDashboardNavbar />
                <div className="content">
                    {children}
                </div>
            </div>
            <div className="right-container">
                <LayoutSidebar />
            </div>
        </div>
    );
}
