import './supplier.css';

const SuccessAlert = () => {
  if(document.getElementById("closeAlert")) {
    document.getElementById("closeAlert").onclick = function() {
      document.getElementById("messageAlert").style.display = 'none';
    };
  }
  return (
    <div className="message-alert" id="messageAlert">
      <span id="closeAlert">x</span>
      <h3>تم إرسال الرسالة بنجاح</h3>
    </div>
  )
}

export default SuccessAlert