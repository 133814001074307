import { useEffect, useState } from 'react';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import InventoryIcon from '@mui/icons-material/Inventory';
import StorefrontIcon from '@mui/icons-material/Storefront';
import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer';
import SupplierEmail from './SupplierEmail';
import SuccessAlert from './SuccessAlert';
import './supplier.css';

const Supplier = () => {
  const [showAlert, setShowAlert] = useState(false);
  useEffect(() => {
    window.scroll(0, 0)
  }, []);
  return (
    <div className="supplier-page">
      {showAlert && <SuccessAlert />}
      <Navbar />
      <div className="heading">
        <div className="overlay">
          <h1>هل ترغب بعرض منتجاتك؟</h1>
          <p>قم بعرض منتجاتك على تجار الأردن عبر بريميوم ستور</p>
        </div>
      </div>
      <div className="container">
        <div className="features">
          <div className="feature-box">
            <CameraAltIcon className="supply-feature-icon"/>
            <h3 className="feature-title">تصوير</h3>
            <p className="feature-description">نقوم بتصوير منتجاتك بطريقة إحترافية</p>
          </div>
          <div className="feature-box">
            <InventoryIcon className="supply-feature-icon"/>
            <h3 className="feature-title">تخزين</h3>
            <p className="feature-description">اضمن تخزين منتجاتك في بيئة امنة ومجهزة</p>
          </div>
          <div className="feature-box">
            <StorefrontIcon className="supply-feature-icon"/>
            <h3 className="feature-title">تسويق</h3>
            <p className="feature-description">عرض منتجاتك على عدد كبير من التجار في الأردن</p>
          </div>
        </div>
        </div>
        <div className="supplier-contact">
          <div className="container">
            <div className="row">
              <div className="supplier-form col-md-6">
                <SupplierEmail setShowAlert={setShowAlert}/>
              </div>
              <div className="supplier-textbox col-md-6">
                <h5>سارع بعرض منتجاتك في بريميوم ستور</h5>
              </div>
            </div>
          </div>
      </div>
      <Footer />
    </div>
  )
}

export default Supplier;
