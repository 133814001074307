import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import './signup.css';
import { api } from '../../app/store';

const PASSWORD_CHECK = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])[a-zA-Z0-9\W_]{8,}$/g;

const Signup = () => {
    if (sessionStorage.getItem("userId")) window.location.pathname = '/';
    const [signupError, setSignupError] = useState({ visisble: false, error: "" });
    const [hint, setHint] = useState({ visible: false, hint: "يجب أن تحتوي كلمة المرور على حرف كبير وحرف صغير ورقم وعلامة خاصة على الأقل وثمانية أحرف" });

    const [createUser] = api.useCreateUserMutation({
        fixedCacheKey: "users"
    });

    const showPassword = () => {
        let input = document.querySelector("#passwordField");
        input.setAttribute("type", "text");
    };

    const hidePassword = () => {
        let input = document.querySelector("#passwordField");
        input.setAttribute("type", "password");
    };

    const showHint = (e) => {
        if (PASSWORD_CHECK.test(e.target.value)) {
            setHint(prev => ({ ...prev, visible: false }));
        } else {
            setHint(prev => ({ ...prev, visible: true }));
        }
    };

    const handleSubmit = e => {
        e.preventDefault();

        createUser({
            username: document.querySelector("#usernameField").value,
            email: document.querySelector("#emailField").value,
            phone_number: document.querySelector("#phoneNumberField").value,
            password: document.querySelector("#passwordField").value
        })
            .unwrap()
            .then(result => {
                if (result.message !== `User created successfully`) throw Error('مشكلة!')
                sessionStorage.setItem("userId", result.id);
                window.location.pathname = '/dashboard';
            })
            .catch(err => {
                console.log(err);
                setSignupError({ visisble: true, error: err.data.message });
            });
    }

    return (
        <div className="sign-up">
            <div className="overlay">
                <div className="container">
                    <h1>إنشاء حساب</h1>
                    <form onSubmit={handleSubmit} className="sign-up-form">
                        <div className="input-group mb-3">
                            <input id="emailField" type="email" className="form-control" aria-label="Recipient's email" aria-describedby="basic-addon2" required />
                            <span className="input-group-text">البريد الاكتروني</span>
                        </div>
                        <div className="input-group mb-3">
                            <input id="usernameField" type="text" className="form-control" aria-label="Recipient's email" aria-describedby="basic-addon2" required />
                            <span className="input-group-text">اسم الحساب</span>
                        </div>
                        <div className="input-group mb-3">
                            <input id="phoneNumberField" type="text" placeholder="07********" className="form-control" aria-label="Recipient's email" aria-describedby="basic-addon2" required />
                            <span className="input-group-text">رقم الهاتف</span>
                        </div>
                        <div className="input-group mb-3">
                            <input onChange={showHint} onFocus={() => {
                                setHint(prev => ({ ...prev, visible: true }));
                            }} id="passwordField" type="password" className="form-control" placeholder="كلمة المرور" aria-label="Recipient's password" aria-describedby="basic-addon2" required />
                            <span className="input-group-text" onMouseOver={showPassword} onMouseOut={hidePassword}><RemoveRedEyeIcon /></span>
                        </div>
                        {hint.visible && <div className='alert alert-danger'>
                            <strong>{hint.hint}</strong>
                        </div>}
                        <p className="has-no-acc">لديك حساب مسبقا؟ قم <Link to="/sign-in">بتسجيل الدخول</Link></p>
                        {signupError.visisble && <div className="alert alert-danger" role="alert">
                            {signupError.error}
                        </div>}
                        <input className="btn" type="submit" value="دخول" />
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Signup
