import Header from './Header';
import Navbar from '../../components/navbar/Navbar';
import './home.css';
import Aboutus from './Aboutus';
import Features from './Features';
import Contact from './Contact';
import Footer from '../../components/footer/Footer';

const Home = () => {
  return (
    <div className='home'>
      <Navbar />
      <Header />
      <Aboutus />
      <Features />
      <Contact />
      <Footer />
    </div>
  )
}

export default Home