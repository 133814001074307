import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/home/Home';
import Aboutus from './pages/aboutus/Aboutus';
import Supplier from './pages/supplier/Supplier';
import Contactus from './pages/contactus/Contactus';
import Signin from './pages/signin/Signin';
import Signup from './pages/signup/Signup';
import Main from './pages/dashboard/maindashboard/Main';
import Profile from './pages/dashboard/profile/Profile';
import Wallet from './pages/dashboard/wallet/Wallet';
import CreateProduct from './pages/admin/products/create/CreateProduct';
import ListProducts from './pages/admin/products/list/ListProducts';
import Catalog from './pages/dashboard/catalog/Catalog';
import ProductDetails from './pages/dashboard/productdetails/ProductDetails';
import FavoriteProducts from './pages/dashboard/favoriteproducts/FavoriteProducts';
import ShoppingCart from './pages/dashboard/shoppingcart/ShoppingCart';
import Orders from './pages/dashboard/orders/Orders';
import OrderDetails from './pages/dashboard/orderdetails/OrderDetails';
import AdminHome from './pages/admin/adminhome/AdminHome';
import ProductsMain from './pages/admin/products/main/ProductsMain';
import UsersList from './pages/admin/users/UsersList';
import ViewUser from './pages/admin/users/viewuser/ViewUser';
import OrdersMain from './pages/admin/orders/OrdersMain';
import { OrderDetails as AdminOrderDetails } from './pages/admin/orders/orderdetails/OrderDetails';
import EditProduct from './pages/admin/products/edit/EditProduct';
import { ApiProvider } from '@reduxjs/toolkit/dist/query/react';
import { api } from './app/store';
import "./global.css";
import DashboardLayout from './pages/dashboard/DashboardLayout';
import Terms from './pages/home/Terms';
import WithdrawOrdersMain from './pages/admin/withdraw_orders/WithdrawOrders';
import Guide from './pages/dashboard/guide/Guide';

function App() {

    return (
        <div className="App">
            <ApiProvider api={api}>
                <Router>
                    <Routes>
                        <Route path='/'>
                            <Route index element={<Home />} />
                            <Route path="about" element={<Aboutus />} />
                            <Route path="supplier" element={<Supplier />} />
                            <Route path="contact-us" element={<Contactus />} />
                            <Route path="sign-in" element={<Signin />} />
                            <Route path="sign-up" element={<Signup />} />
                            <Route path="terms" element={<Terms />} />
                            <Route path="dashboard/">
                                <Route index element={<DashboardLayout><Main /></DashboardLayout>} />
                                <Route path="profile" element={<DashboardLayout><Profile /></DashboardLayout>} />
                                <Route path="wallet" element={<DashboardLayout><Wallet /></DashboardLayout>} />
                                <Route path="catalog/">
                                    <Route index element={<DashboardLayout><Catalog /></DashboardLayout>} />
                                    <Route path="details/:productId" element={<ProductDetails />} />
                                </Route>
                                <Route path="favorite-products" element={<DashboardLayout><FavoriteProducts /></DashboardLayout>} />
                                <Route path="shopping-cart" element={<DashboardLayout><ShoppingCart /></DashboardLayout>} />
                                <Route path="orders/">
                                    <Route index element={<DashboardLayout><Orders /></DashboardLayout>} />
                                    <Route path="details/:id" element={<DashboardLayout><OrderDetails /></DashboardLayout>} />
                                </Route>
                                <Route path="guide" element={<DashboardLayout><Guide /></DashboardLayout>} />
                            </Route>

                            <Route path="admin/">
                                <Route index element={<AdminHome />} />
                                <Route path="products/">
                                    <Route index element={<ProductsMain />} />
                                    <Route path="create-product" element={<CreateProduct />} />
                                    <Route path="list-products" element={<ListProducts />} />
                                    <Route path="edit/:productId" element={<EditProduct />} />
                                </Route>
                                <Route path="users/">
                                    <Route index element={<UsersList />} />
                                    <Route path="view/:id" element={<ViewUser />} />
                                </Route>
                                <Route path="orders/">
                                    <Route index element={<OrdersMain />} />
                                    <Route path=":id" element={<AdminOrderDetails />} />
                                </Route>
                                <Route path="withdraw-orders/">
                                    <Route index element={<WithdrawOrdersMain />} />
                                </Route>
                            </Route>
                        </Route>
                    </Routes>
                </Router>
            </ApiProvider>
        </div>
    );
}

export default App;
