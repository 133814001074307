import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import './total_available_balance.css';

const TotalAvailableBalance = ({balance}) => {

  return (
    <div className="total-available-balance">
      <h1 className="title">المحفظة المالية</h1>
      <MonetizationOnIcon className="money-icon" />
      <h2>إجمالي الرصيد المتاح</h2>
      <p><span>{balance.toString()}</span> JD</p>
    </div>
  )
}

export default TotalAvailableBalance