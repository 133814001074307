export default [ 
    {
        "title": "إنشاء الحساب",
        "link": "https://www.youtube.com/embed/MkV7i-jBF0I?si=A5WQ0JbiSEuqFSH-"
    },
    {
        "title": "تسجيل الدخول",
        "link": "https://www.youtube.com/embed/xZx_LsSJ-yg?si=DNkk_2FT0aS-H67o"
    },
    {
        "title": "منتجاتي",
        "link": "https://www.youtube.com/embed/nw3oL4UXI-0?si=PJiOi6nPkzcfhAOi"
    },
    {
        "title": "إضافة منتج",
        "link": "https://www.youtube.com/embed/uYU4wjokhr8?si=JEq1suNUbD8um_wM"
    },
    {
        "title": "سلة المشتريات",
        "link": "https://www.youtube.com/embed/_UbmU_c2Fnc?si=Z4d1MNF8et-DD1yF"
    },
];
