import { useParams } from 'react-router-dom';
import LoadingPatternOne from '../../../dashboard/loadingpatterns/LoadingPatternOne';
import OrderSummary from './OrderSummary';
import ShippingDetails from './ShippingDetails';
import './orderdetails.css';
import ChangeOrderState from './ChangeOrderState';
import { useGetLocalUserData } from '../../../../functions/useGetLocalUserData';
import useAuthChecker from '../../useAuthChecker';
import { api } from '../../../../app/store';
import { OrderProducts } from './OrderProducts';

const OrderDetails = () => {

  const { userData } = useGetLocalUserData();
  useAuthChecker(userData);

  const { id } = useParams();
  const { data: orderData, isLoading } = api.useGetOrderByIdQuery(id);
  if (isLoading) {
    return <LoadingPatternOne />
  }
  return (orderData &&
    <div className='admin-order-details'>
      <div className="container">
        <h1>Order Details</h1>
        <div className="row">
          <div className="flex-container">
            <OrderSummary order={JSON.stringify(orderData)} />
            <div style={{ flex: 1 }}>
              <ShippingDetails order={JSON.stringify(orderData)} />
              <ChangeOrderState order={JSON.stringify(orderData)} />
            </div>
          </div>
        </div>
        <div className="row">
          <OrderProducts products={JSON.stringify(orderData.products)} />
        </div>
      </div>
    </div>
  )
}

export { OrderDetails }
